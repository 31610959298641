import { Injectable } from '@angular/core';
import { AuthStrategy } from '@libs/services/auth-strategy';
import { AuthService } from './auth.service'; // Assuming you have AuthService in the app

@Injectable({ providedIn: 'root' })
export class AppAuthStrategy extends AuthStrategy {
  constructor(private readonly authService: AuthService) {
    super();
  }

  async signOut(): Promise<void> {
    await this.authService.signOut();
  }
}
