import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
// import { StorageService } from '../services/storage.servcie';

export const routeGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  // const storageService = inject(StorageService);

  const isAuthenticated = await authService.isAuthenticated();
  if (isAuthenticated) return true;

  localStorage.setItem('REDIRECT_LOCATION', location.href.replace(location.origin, ''));
  authService.goToLogin();
  return false;
};
