import { Routes } from '@angular/router';
import { GlobalNavigationComponent } from './pages/global/global.navigation';
import { routeGuard } from './guards/route.guard';
import { registrationGuard } from './guards/registration.guard';
import { loginGuard } from './guards/login.guard';
import { registrationRoutes } from './pages/registration/registration.routes';
import { accountRoutes } from './pages/account/account.routes';
import { getCareRoutes } from './pages/get-care/get-care.routes';
import { HomeBaseComponent } from './pages/home/home-base.component';

export const routes: Routes = [
  {
    path: 'invite/accept/:id',
    loadComponent: () =>
      import('./pages/account/invite/accept-request/accept-request.page').then((m) => m.AcceptRequestPage),
  },
  {
    path: 'patient-transfer-access',
    loadComponent: () =>
      import('./pages/account/connected-accounts/accept-transfer/accept-transfer.page').then(
        (m) => m.AcceptTransferAccessPage,
      ),
  },
  {
    path: 'patient-transfer-access/incorrect-dob',
    loadComponent: () =>
      import('./pages/account/connected-accounts/incorrect-dob/incorrect-dob.page').then((m) => m.IncorrectDobPage),
  },
  {
    path: 'registration',
    canActivate: [routeGuard],
    loadComponent: () => import('./pages/registration/registration.page').then((m) => m.RegistrationPage),
    children: registrationRoutes,
  },
  {
    path: 'waiting-room/:hash',
    loadComponent: () => import('./pages/waiting-room/waiting-room.component').then((m) => m.WaitingRoomComponent),
    data: { shouldShowTabBar: false },
  },
  {
    path: 'waiting-room/:hash/video',
    loadComponent: () => import('./pages/olla-video/video-base/video-base.page').then((m) => m.VideoCallBaseComponent),
    data: { shouldShowTabBar: false },
  },
  {
    path: '',
    component: GlobalNavigationComponent,
    canActivate: [routeGuard, registrationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeBaseComponent,
        data: { shouldShowTabBar: true },
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
          },
        ],
      },
      {
        path: 'checkout',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/checkout/checkout.page').then((m) => m.CheckoutPage),
          },
          {
            path: 'payment-confirmation',
            pathMatch: 'full',
            loadComponent: () =>
              import('./pages/checkout/payment-confirmation/payment-confirmation.page').then(
                (m) => m.PaymentConfirmationPage,
              ),
          },
        ],
      },
      {
        path: 'care',
        loadComponent: () => import('./pages/get-care/get-care.page').then((m) => m.GetCarePage),
        data: { shouldShowTabBar: true },
        children: getCareRoutes,
      },
      {
        path: 'support',
        loadComponent: () => import('./pages/support/support.page').then((m) => m.SupportPage),
        data: { shouldShowTabBar: true },
      },
      {
        path: 'account',
        loadComponent: () => import('./pages/account/account.page').then((m) => m.AccountPage),
        data: { shouldShowTabBar: true },
        children: accountRoutes,
      },
    ],
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        canActivate: [loginGuard],
        loadComponent: () => import('./pages/login/login.page').then((m) => m.LoginPage),
      },
    ],
  },
  {
    path: 'verify',
    loadComponent: () => import('./pages/login/verify/verify.page').then((m) => m.VerifyPage),
  },
  {
    path: '404',
    canActivate: [routeGuard, registrationGuard],
    loadComponent: () => import('./pages/404/not-found.page').then((m) => m.NotFoundPage),
  },
  {
    path: ':hash',
    loadComponent: () => import('./pages/link-shortening/link-shortening.page').then((m) => m.LinkShorteningPage),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: 'support',
    loadComponent: () => import('./pages/support/support.page').then((m) => m.SupportPage),
  },
];
