import { NgClass } from '@angular/common';
import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { IonIcon, IonTabBar, IonTabButton, IonTabs } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProfilesService } from '../../services/profiles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getDataFromRoute } from 'src/app/shared/utils/get-data-from-route';

// import { StorageService } from '../../services/storage.servcie';

@Component({
  selector: 'app-global-navigation',
  templateUrl: 'global.navigation.html',
  standalone: true,
  imports: [IonTabs, IonTabButton, IonIcon, IonTabBar, NgClass, TranslateModule],
})
export class GlobalNavigationComponent implements OnInit, OnDestroy {
  platformReady: boolean = false;
  currentPlatform: string = '';
  isPrime!: boolean;
  tabs = [
    { tab: 'home', label: 'home.home', icon: 'icon-home' },
    { tab: 'care', label: 'home.care', icon: 'icon-care' },
    { tab: 'support', label: 'home.support', icon: 'icon-support' },
    { tab: 'account', label: 'home.account', icon: 'icon-user' },
  ];
  showTabBar?: boolean = true;
  subscription = new Subscription();
  isLoading = false;

  constructor(
    private readonly authService: AuthService,
    private readonly platform: CurrentPlatformService,
    private readonly httpClient: HttpClient,
    private readonly profileService: ProfilesService,
    // private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    effect(() => {
      this.isPrime = this.authService.isPrime();
    });
  }

  async ngOnInit(): Promise<void> {
    this.platformReady = await this.platform.isPlatformReady();
    this.currentPlatform = this.platform.getCurrentPlatform();
    localStorage.removeItem('REDIRECT_LOCATION');

    this.subscription = getDataFromRoute(this.router, this.activatedRoute).subscribe(
      (data) => (this.showTabBar = data.shouldShowTabBar),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onTabChange(event: any): Promise<void> {
    this.isLoading = true;
    if (event.tab === 'account') {
      this.profileService.updateForceFetch(true);
    }
    const tokenData = await this.authService.getTokenPayload();
    if (tokenData.subUser) {
      this.authService.setSwitchingState(true);
      try {
        const token = await this.authService.getToken();
        const res: any = (await firstValueFrom(
          this.httpClient.post(
            `${environment.ollaApiUrl}/api/supertokens/account/switch-account`,
            { patientId: tokenData.patientId },
            {
              headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-TENANT-ID': environment.orgSlug,
              },
            },
          ),
        )) as HttpResponse<any>;
        if (res.status < 200 || res.status >= 300) throw new Error();
        this.authService.setPatientData(res);
        this.authService.setPrimeAndLanguage(tokenData.isPrime, res.language);
      } finally {
        this.authService.setSwitchingState(false);
      }
    }
  }

  async onTabChanged(): Promise<any> {
    setTimeout(() => {
      this.isLoading = false;
    }, 200);
  }
}
