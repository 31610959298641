import { Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss',
  imports: [LottieComponent],
  standalone: true,
})
export class SplashScreenComponent {
  lottieOptions: AnimationOptions = {
    path: 'assets/lottie/splash-standard-logo.json',
  };
}
