import { Routes } from '@angular/router';

export const registrationRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'my-profile',
  },
  {
    path: 'my-profile',
    loadComponent: () => import('./add-my-profile/add-my-profile.page').then((m) => m.AddMyProfilePage),
    data: {
      progress: 50,
      label: 'registration.subtitle.myProfile',
    },
  },
  {
    path: 'already-verified',
    loadComponent: () => import('./already-verified/already-verified.page').then((m) => m.AlreadyVerifiedPage),
  },
  {
    path: 'address',
    loadComponent: () => import('./address/address.component').then((m) => m.AddressComponent),
  },
  {
    path: 'account-matched',
    loadComponent: () => import('./account-matched/account-matched.page').then((m) => m.AccountMatchedPage),
  },
  {
    path: 'registration-splash',
    loadComponent: () => import('./registration-splash/registration-splash.page').then((m) => m.RegistrationSplashPage),
  },
  {
    path: 'partial-match',
    loadComponent: () => import('./partial-match/partial-match-modal.component').then((m) => m.PartialMatchModal),
  },
  {
    path: 'no-match',
    loadComponent: () => import('./no-match/no-match.page').then((m) => m.NoMatchPage),
    data: {
      progress: 50,
      label: 'registration.subtitle.myProfile',
    },
  },
  {
    path: 'unable-to-match',
    loadComponent: () => import('./unable-to-match/unable-to-match.page').then((m) => m.UnableToMatchPage),
  },
  {
    path: 'contact-confirmation',
    loadComponent: () =>
      import('./contact-confirmation/contact-confirmation.page').then((m) => m.ContactConfirmationPage),
  },
  {
    path: 'contact-summary',
    loadComponent: () => import('./contact-summary/contact-summary.page').then((m) => m.ContactSummaryPage),
  },
  {
    path: 'dob-verification',
    loadComponent: () => import('./dob-verification/dob-verification.page').then((m) => m.DobVerificationPage),
  },
  {
    path: 'registration-option',
    loadComponent: () =>
      import('./registration-option/registration-option.component').then((m) => m.RegistrationOptionComponent),
  },
  {
    path: 'profile-setup-confirmation',
    loadComponent: () =>
      import('./profile-setup-confirmation/profile-setup-confirmation.component').then(
        (m) => m.ProfileSetupConfirmationComponent,
      ),
  },
  {
    path: 'add-profile',
    loadComponent: () =>
      import('./add-profile-options/add-profile-options.component').then((m) => m.AddProfileOptionsComponent),
  },
  {
    path: 'add-profile-family',
    loadComponent: () =>
      import('./add-profile-family/add-profile-family.component').then((m) => m.AddProfileFamilyComponent),
    data: {
      progress: 75,
      label: 'registration.subtitle.familyMember',
    },
  },
];
