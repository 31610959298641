import { Component, NgZone } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as SuperTokens from 'supertokens-web-js';
import getCookieHandler from './cookie-handler';
import Passwordless from 'supertokens-web-js/recipe/passwordless';
import { RecipePreAPIHookContext } from 'supertokens-web-js/lib/build/recipe/recipeModule/types';
import Session, { RecipeInterface } from 'supertokens-web-js/recipe/session';
import { PasswordlessClaim } from './claims/passwordless.claim';
import { BodyBackgroundService } from './services/body-background.service';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { enterLeaveAnimation } from './animations/enter-leave.animation';

SuperTokens.init({
  appInfo: {
    appName: 'Olla',
    apiDomain: environment.ollaApiUrl,
    apiBasePath: '/api/supertokens',
  },
  cookieHandler: getCookieHandler,
  recipeList: [
    Passwordless.init({
      preAPIHook: async (context) => addHeaders(context),
    }),
    Session.init({
      override: {
        functions(oI: RecipeInterface) {
          return {
            ...oI,
            getGlobalClaimValidators: ({ claimValidatorsAddedByOtherRecipes }): any => {
              return [...claimValidatorsAddedByOtherRecipes, PasswordlessClaim.validators.isTrue()];
            },
          };
        },
      },
      tokenTransferMethod: 'header',
      sessionTokenFrontendDomain: environment.sessionTokenFrontendDomain,
      preAPIHook: async (context) => addHeaders(context),
    }),
  ],
});

function addHeaders(context: RecipePreAPIHookContext<any>): RecipePreAPIHookContext<any> {
  const headers: any = {
    ...context.requestInit.headers,
    'X-USER-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (context.userContext?.redirectUri) {
    headers['X-REDIRECT-URI'] = context.userContext.redirectUri;
    headers['X-ORIGIN'] = environment.redirectUri;
  }
  if (context.userContext?.qrh) {
    headers['X-QRH'] = context.userContext.qrh;
  }
  if (context.userContext?.org) {
    headers['X-TENANT-ID'] = context.userContext.org;
  }
  if (context.userContext?.userEmail) {
    headers['X-LOGIN-HINT'] = context.userContext.userEmail;
  }
  if (context.action === 'REFRESH_SESSION') {
    for (const cookie of document.cookie.split('; ')) {
      const [key, value] = cookie.split('=');
      if (decodeURIComponent(key) === 'st-refresh-token') {
        headers['Authorization'] = `Bearer ${decodeURIComponent(value)}`;
        break;
      }
    }
  }

  context.requestInit.headers = headers;
  return context;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, SplashScreenComponent],
  animations: [enterLeaveAnimation],
})
export class AppComponent {
  showSplash = false;

  constructor(
    readonly translate: TranslateService,
    private readonly bodyBackgroundService: BodyBackgroundService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly platform: Platform,
  ) {
    // TODO: This needs to be implemented using CurrentPlatformService
    // We want to show splash animation just on native apps not on browsers
    this.platform.ready().then(() => {
      if (!this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
        // There is a few seconds gap before the first route is loaded, so we need to set background before that
        this.bodyBackgroundService.setBackground();
        this.showSplash = true;
        setTimeout(() => {
          this.showSplash = false;
        }, 5000);
      }
      document.documentElement.style.setProperty(
        '--ion-toolbar-padding-top',
        this.platform.is('ios') && !this.platform.is('mobileweb') ? '3.5rem' : '1rem',
      );
    });
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang() || 'en';
    translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    this.bodyBackgroundService.subscribeToRouteEvents();
    this.initUniversalLinks();
  }

  private initUniversalLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        //eslint-disable-next-line
        const slug = `/${event.url.replace(/^https:\/\/[^\/]*\//, '')}`;
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
