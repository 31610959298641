export const registration = {
  title: {
    provideMoreInfo: 'Thanks, {{ name }}! Could you please tell us a bit more about yourself?',
    contactSummary: 'Could you please review and confirm your information?',
    emailConfirmation: 'We’ve sent you an email to verify.',
    phoneConfirmation: 'We’ve sent you a code!',
    pleaseEnterCode: 'Please enter the 6-digit code sent to {{ value }}.',
    sentYouCodePhone: 'We’ve sent you a code to verify your phone number.',
    sentYouCodeEmail: 'We’ve sent you a code to verify your email.',
    loginConfirmation: 'We are delighted to have you, {{ user }}.',
    infoVerify: 'Let’s start with your personal details.',
    companyConfirmation: 'To help keep your information secure, could you please verify the following?',
    confirmDetails: 'Can you confirm the following details about {{ name }}?',
    verifyContact: 'Let’s finish up by adding your {{ contact }} to your account.',
    verifyFlexibility:
      'When you log in next time, you’ll have the flexibility to use both your phone number and email.',
    searchLanguage: 'Search for a language',
    codeIncorrect: 'The code you entered cannot be verified.',
    alreadyLinked: 'It looks like your {{ contact }} is connected to another account.',
    accountMatched: 'Thank you, {{ user }}! Now let’s make sure you get securely logged in.',
    weAreHavingTrouble: 'We’re having trouble creating or accessing your account...',
    addMissingAddress: 'Could you please add an address to your account?',
    dobVerification: 'Let’s make sure you get securely logged in.',
    phoneNum: 'phone number',
    getStarted: 'Let’s get set up for care with Montana Pediatrics.',
    setUp: 'Set up your profiles for Montana Pediatrics',
    successfullySetup: 'Your profile was set up successfully, {{ name }}!',
    addUser: 'Let’s start with details about your family member or dependent.',
    contactDetails: 'Next, please share your contact details to complete your profile.',
    contactConnected: 'The {{ contact }} you entered is connected to another profile.',
  },
  subtitle: {
    enterEmail:
      'Please enter the email associated with your account. We’ll send you a link to sign in to your Next Level account.',
    enterEmailPhone: 'Please enter your phone number or email to get started.',
    linkSent: 'Your sign-in link has been sent to {{ value }}.',
    verifyEmail: 'Please enter the {{ type }} you use to sign in to the Next Level app.',
    verifyPhone: 'Please enter the phone number you use to sign in to the Next Level app.',
    sendCode: 'Don’t worry, we’d be happy to send the code again to {{ contact }}.',
    contactUs: 'Please sign in using the correct details, or contact us for help.',
    accountMatched: 'Please enter email that you previously used to sign in to the Next Level app.',
    ourTeamIsHere:
      'We’re here to assist you in creating or accessing your account so you can use the Montana Pediatrics App smoothly.',
    dobVerification: 'Could you please verify your date of birth?',
    CanWeHelp: 'How can we help you get started?',
    familyMember: 'Family member profile',
    myProfile: 'My profile',
    singIntoExistingContact:
      'You can sign into the existing profile or use a different {{ contact }} to set up a new profile.',
  },
  content: {
    genderAssigned: 'Your gender assigned at birth:',
    name: 'Name',
    address: 'Address',
    yourAddress: 'Your address',
    personalInfo: 'Personal information',
    preferredLanguage: 'Preferred language',
    contactInfo: 'Contact information:',
    codeInputPhone: 'Please verify your phone number by entering the 6-digit code.',
    codeInputEmail: 'Please verify your email by entering the 6-digit code.',
    aboutYourself: 'Please take a moment to share a few details about you.',
    loginMethod: 'We are currently using the following {{ loginMethod }} to log into your account.',
  },
  actions: {
    continue: 'Continue',
    confirm: 'Confirm',
    resendLink: 'Resend Link',
    verify: 'Verify',
    reEnterEmail: 'Re-enter Email',
    reEnterPhoneNumber: 'Re-enter Phone Number',
    resendCode: 'Send New Code',
    changeEmail: 'Change Email',
    changePhone: 'Change Phone Number',
    contactUs: 'Contact Us',
    differentCredential: 'Use Different Credential',
    signInAgain: 'Sign In Again',
    startChat: 'Start a Chat',
    thePatient: 'I Am the Patient',
    theGuardian: 'I Am the Parent or Guardian',
    startSetup: 'Start by Setting Up Your Profile',
    addFamily: 'Add a Family Member or Dependent',
    completeSetup: 'Complete Profile Setup',
    goToHome: 'Go to Home',
    signIntoProfile: 'Sign in to this profile',
    enterDifferentContact: 'Enter a different {{ contact }}',
  },
  forms: {
    email: 'Email',
    emailPhone: 'Phone number or email',
    firstName: 'First name',
    lastName: 'Last name',
    dob: 'Date of birth',
    dobPlaceholder: 'MM-DD-YYYY',
    genderAssigned: 'Your gender assigned at birth:',
    male: 'Male',
    female: 'Female',
    noAnswer: 'I prefer not to answer',
    phoneNum: 'Phone number',
    phone: 'Phone number',
    streetAddress: 'Address',
    aptNum: 'Apt, suite, etc',
    city: 'City',
    state: 'State',
    zipCode: 'ZIP code',
    textMessages: 'Text messages',
    both: 'Both text message and email',
    confirmAuthorization: 'Please confirm your authorization',
    contactDetails: 'Your contact details',
    prounouns: 'Prounouns',
  },
  email: 'email',
  phone: 'phone',
  error: {
    laguageRequired: 'Please select preferred language',
    stateRequired: 'Please select state',
  },
};
