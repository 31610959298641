export const home = {
  greeting: 'Good morning, {{name}}',
  title: 'Thank you for trusting us with your care.',
  watch: 'Watch',
  home: 'Home',
  care: 'Care',
  support: 'Support',
  account: 'Account',
  updates: 'We’re grabbing new updates for you now!',
  welcome: 'Welcome!',
  ics: {
    virtualAppointment: 'Virtual Appointment',
    urgentCareWalkIn: 'Urgent Care Walk In',
    urgentCareAppointment: 'Urgent Care Appointment',
    nextLevelUrgentCare: 'Next Level Urgent Care',
  },
};
