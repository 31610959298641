export const support = {
  needAssistance: 'Need assistance? We’re here to help.',
  availableTime: 'We’re here for you to chat every Monday through Friday, from 7:30 AM to 6 PM.',
  hereToHelp:
    'From helping you find a primary care provider for your kiddo to answering questions about a bill you received, and everything in between, we are here to help.',
  actions: {
    sendEmail: 'Send us an email',
    speakWithTeam: 'Speak with our care team',
  },
};
