import { Routes } from '@angular/router';
import { PreVisitPage } from './pre-visit/pre-visit.page';
import { InviteRoutes } from '../account/invite/invite.routes';
import { insuranceRoutes } from '../account/insurance/insurance.routes';
import { BackUrl } from '@shared/enums/back-url.enum';

export const getCareRoutes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadComponent: () => import('./select-account/select-account.page').then((m) => m.SelectAccountPage),
    data: { shouldShowTabBar: true },
  },
  {
    path: 'invite',
    loadComponent: () => import('../account/invite/invite.page').then((m) => m.InvitePage),
    children: InviteRoutes,
    data: {
      [BackUrl.InviteNewUserPage]: '/care/account',
      [BackUrl.UploadPhotoPage]: '/care/account',
      [BackUrl.ConnectedConfirmationPage]: '/care/account',
      [BackUrl.InviteRequestSentPage]: '/care/account',
      [BackUrl.RequestAccessPage]: '/care/invite/new',
      [BackUrl.UnableToMatchPage]: '/care/invite/new',
    },
  },
  {
    path: ':profileId',
    loadComponent: () => import('./get-care-base.page').then((m) => m.GetCareBasePage),
    children: [
      {
        path: 'olla-video',
        loadComponent: () => import('./../olla-video/video-base/video-base.page').then((m) => m.VideoCallBaseComponent),
      },
      {
        path: 'intake',
        loadChildren: () => import('./intake/intake.routes').then((m) => m.INTAKE_ROUTES),
      },
      {
        path: 'pre-visit',
        component: PreVisitPage,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'consent',
          },
          {
            path: 'consent',
            loadComponent: () => import('./pre-visit/consent/consent.component').then((m) => m.ConsentComponent),
            data: {
              progress: 25,
              label: 'getCare.consent',
            },
          },
          {
            path: 'employer',
            loadComponent: () =>
              import('./pre-visit/employer/choose-employer.component').then((m) => m.ChooseEmployerComponent),
            data: {
              progress: 50,
              label: 'getCare.benefits',
            },
          },
          {
            path: 'primary-care-provider',
            loadComponent: () =>
              import('./pre-visit/primary-provider/primary-provider.component').then((m) => m.PrimaryProviderComponent),
            data: {
              progress: 75,
              label: 'getCare.primaryCareProvider',
            },
          },
        ],
      },
      {
        path: 'insurance',
        loadComponent: () => import('../account/insurance/insurance.page').then((m) => m.InsurancePage),
        children: [
          ...insuranceRoutes,
          {
            path: 'insurance-confirmation',
            loadComponent: () =>
              import('./insurance-confirmation/insurance-confirmation.component').then(
                (m) => m.InsuranceConfirmationComponent,
              ),
          },
        ],
      },
      {
        path: 'payment-preferences',
        loadComponent: () =>
          import('./payment-preferences/payment-preferences.page').then((m) => m.PaymentPreferencesPage),
        data: { shouldShowTabBar: false },
      },
      {
        path: 'payment-covered-confirmation',
        loadComponent: () =>
          import('./payment-covered-confirmation/payment-covered-confirmation.component').then(
            (m) => m.PaymentCoveredConfirmationComponent,
          ),
        data: { shouldShowTabBar: false },
      },
      {
        path: 'payment-later',
        loadComponent: () => import('./payment-later/payment-later.page').then((m) => m.PaymentLaterPage),
        data: { shouldShowTabBar: false },
      },
    ],
  },
];
