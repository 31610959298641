export const globalErrors = {
  somethingWentWrong: 'Oops, something went wrong',
  tryRefreshing: 'Please try refreshing the application.',
  weExperiencedIssue: 'We’ve experienced an issue. Try refreshing the page.',
  ifIssuePersist: 'If the issue persists, please reach out to our support team.',
  sendCode: 'Error sending code to the user.',
  updatingData: 'Error while updating data',
  contactSupport: 'Contact Support',
  retry: 'Retry',
};
