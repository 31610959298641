import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular/standalone';

export const loginGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const navCtrl = inject(NavController);

  const isAuthenticated: boolean = await authService.isAuthenticated();
  if (isAuthenticated) {
    navCtrl.navigateForward(['home']);
    return false;
  }
  return true;
};
