import { Component } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ProgressBarComponent } from 'src/app/shared/progress-bar/progress-bar.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { map, Observable } from 'rxjs';
import { getDataFromRoute } from 'src/app/utils/get-data-from-route';

@Component({
  selector: 'app-pre-visit',
  templateUrl: './pre-visit.page.html',
  styleUrl: './pre-visit.page.scss',
  standalone: true,
  imports: [
    IonBackButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonTitle,
    IonToolbar,
    TranslateModule,
    ProgressBarComponent,
    RouterOutlet,
    NgClass,
    AsyncPipe,
  ],
})
export class PreVisitPage {
  meta$: Observable<any>;
  backUrl?: string = '/care';

  constructor(
    readonly router: Router,
    readonly route: ActivatedRoute,
  ) {
    this.meta$ = getDataFromRoute(router, route).pipe(
      map((data: any) => ({
        progress: data['progress'],
        label: data['label'],
      })),
    );
  }
}
