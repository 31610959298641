import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Observable, startWith } from 'rxjs';

export function getDataFromRoute(router: Router, activatedRoute: ActivatedRoute): Observable<any> {
  return router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    startWith(() => true),
    map(() => activatedRoute),
    mergeMap(() => {
      let current = activatedRoute.root;
      while (current.firstChild) {
        current = current.firstChild;
      }
      return current.data;
    }),
  );
}
