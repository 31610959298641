export const support = {
  title: {
    chatWithUs: 'Chat with us',
    emailUs: 'Email us',
  },
  needAssistance: 'Need assistance? We’re here to help.',
  availableTime: 'We’re here for you to chat every Monday through Friday, from 7:30 AM to 6 PM.',
  hereToHelp: 'Need help finding a provider, answering billing questions, or anything else? We’re here for you.',
  callUsAt: 'Call us at',
  callSupportAt: 'Please call support at',
  actions: {
    sendEmail: 'Send Us an Email',
    speakWithCareTeam: 'Speak with our care team',
  },
};
