import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular/standalone';

// This guard is preventing user from entering other pages without previously setting up profile
// Token is containing ID that is telling us if user is onboarded or not
// Guard should be used only on routes to which logged-in user has access to
export const registrationGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const navController = inject(NavController);
  const token = await authService.getTokenPayload();

  // if there is no user we go straight to registration without fetching additional data
  if (!token.patientId) {
    await navController.navigateForward(['registration/registration-option']);
    return false;
  }
  const patient = authService.patientData || (await authService.getPatientData());

  // we navigate the user to next step if there is one, preventing further app use
  const redirectUrl = getNextStep(patient, token);
  if (redirectUrl) {
    await navController.navigateForward(redirectUrl);
    return false;
  }

  return true;
};

function getNextStep(patient: any, token: any): string | undefined {
  if (!token?.trusted?.v) return '/registration/dob-verification';
  if (!patient.isEmailVerified || !patient.isPhoneVerified) return '/registration/contact-confirmation';
  if (!patient.isAddressVerified) return '/registration/address';

  return;
}
