import { Routes } from '@angular/router';
import { InviteRoutes } from './invite/invite.routes';
import { AccountBaseComponent } from './account-base.component';
import { personalInformationRoutes } from './personal-information/personal-information.routes';
import { insuranceRoutes } from './insurance/insurance.routes';
import { connectedAccountsRoutes } from './connected-accounts/connected-accounts.routes';
import { BackUrl } from '@shared/enums/back-url.enum';

export const accountRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./chose-account/chose-account.page').then((m) => m.ChoseAccountPage),
  },
  {
    path: 'add',
    loadComponent: () => import('./invite/invite.page').then((m) => m.InvitePage),
    children: InviteRoutes,
    data: {
      [BackUrl.InviteRequestSentPage]: '/account',
      [BackUrl.UploadPhotoPage]: '/account',
      [BackUrl.ConnectedConfirmationPage]: '/account',
      [BackUrl.InviteNewUserPage]: '/account',
      [BackUrl.RequestAccessPage]: '/account/add/new',
      [BackUrl.UnableToMatchPage]: '/account/add/new',
    },
  },
  {
    path: ':id',
    component: AccountBaseComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./account-options/account-options.page').then((m) => m.AccountOptionsPage),
      },
      {
        path: 'personal-information',
        loadComponent: () =>
          import('./personal-information/personal-information.page').then((m) => m.PersonalInformationPage),
        children: personalInformationRoutes,
      },
      {
        path: 'insurance',
        loadComponent: () => import('./insurance/insurance.page').then((m) => m.InsurancePage),
        children: insuranceRoutes,
      },
      {
        path: 'benefits',
        loadComponent: () => import('./benefits/benefits.page').then((m) => m.BenefitsPage),
      },
      {
        path: 'primary-provider',
        loadComponent: () => import('./primary-provider/primary-provider.page').then((m) => m.PrimaryProviderPage),
      },
      {
        path: 'connected',
        loadComponent: () =>
          import('./connected-accounts/connected-accounts.page').then((m) => m.ConnectedAccountsPage),
        children: connectedAccountsRoutes,
      },
      {
        path: 'add',
        loadComponent: () => import('./invite/invite.page').then((m) => m.InvitePage),
        children: InviteRoutes,
        data: {
          [BackUrl.InviteNewUserPage]: '/account/:id/connected/list',
          [BackUrl.UploadPhotoPage]: '/account/:id/connected/list',
          [BackUrl.ConnectedConfirmationPage]: '/account/:id/connected/list',
          [BackUrl.InviteRequestSentPage]: '/account/:id/connected/list',
          [BackUrl.RequestAccessPage]: '/account/:id/add/new',
          [BackUrl.UnableToMatchPage]: '/account/:id/add/new',
        },
      },
      {
        path: 'contact-information',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./contact-information/contact-information.component').then((m) => m.ContactInformationComponent),
          },
          {
            path: 'review-contact-info',
            loadComponent: () =>
              import('./contact-information/review-contact-info/review-contact-info.component').then(
                (m) => m.ReviewContactInfoComponent,
              ),
          },
          {
            path: 'review-address-info',
            loadComponent: () =>
              import('./contact-information/review-address-info/review-address-info.component').then(
                (m) => m.ReviewAddressInfoComponent,
              ),
          },
          {
            path: 'verify-contact-info',
            loadComponent: () =>
              import('./contact-information/verify-contact-info/verify-contact-info.component').then(
                (m) => m.VerifyContactInfoComponent,
              ),
          },
          {
            path: 'confirm-contact-info',
            loadComponent: () =>
              import('./contact-information/confirm-contact-info/confirm-contact-info.component').then(
                (m) => m.ConfirmContactInfoComponent,
              ),
          },
          {
            path: 'address-contact-info',
            loadComponent: () =>
              import('./contact-information/address-contact-info/address-contact-info.component').then(
                (m) => m.AddressContactInfoComponent,
              ),
          },
          {
            path: ':type',
            loadComponent: () =>
              import('./contact-information/update-contact-info/update-contact-info.component').then(
                (m) => m.UpdateContactInfoComponent,
              ),
          },
        ],
      },
    ],
  },
];
