export const notification = {
  upcomingVisit: 'Upcoming visit',
  labResults: 'Lab results',
  getCare: 'Get care',
  prescriptionSavings: 'Prescription savings',

  getDirections: 'Get directions',
  addToCalendar: 'Add to my calendar',
  cancel: 'Cancel',
  cbc: 'CBC',
  metabolic: 'Comprehensive Metabolic ...',
  inPersonCare: 'In-Person Care',
  virtualCare: 'Virtual Care',
  skipAVisit: 'Skip a Visit',
  findPrescriptions: 'Find your prescriptions',
}
