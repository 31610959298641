import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as en from './i18n/en';
import * as es from './i18n/es';

export class LocalTranslationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    let translations;
    switch (lang) {
      case 'es': {
        translations = es;
        break;
      }
      case 'en':
      default:
        translations = en;
    }
    return of(translations);
  }
}
