export const checkout = {
  orders: 'Tus pedidos',
  laboratoryTest: 'Pruebas de laboratorio',
  yourOrders: 'Aquí están tus pedidos por los que solicitamos pago hoy.',
  subtotal: 'Subtotal',
  total: 'Total',
  thankYou: '¡Gracias, {{ name }}!',
  paymentConfirmation: 'Hemos enviado la confirmación de pago a tu correo electrónico.',
  actions: {
    payApple: 'Pagar con',
    payCard: 'Pagar con tarjeta de crédito',
    payNow: 'Pagar ahora',
  },
  forms: {
    cardNumber: 'Número de tarjeta',
    expirationDate: 'Fecha de vencimiento (MM/YY)',
    ccv: 'CCV',
    name: 'Nombre',
    lastName: 'Apellido',
  },
};
