import { Routes } from '@angular/router';

export const insuranceRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: 'list',
    loadComponent: () => import('./insurance-list/insurance-list.page').then((m) => m.InsuranceListPage),
  },
  {
    path: 'insurance-search',
    loadComponent: () => import('./insurance-search/insurance-search.page').then((m) => m.InsuranceSearchPage),
  },
  {
    path: 'add-insurance',
    loadComponent: () => import('./add-insurance/add-insurance.page').then((m) => m.AddInsurancePage),
  },
  {
    path: 'capture',
    loadComponent: () => import('./capture-card/capture-card.page').then((m) => m.CaptureCardPage),
  },
  {
    path: 'capture-check',
    loadComponent: () => import('./capture-check/capture-check.page').then((m) => m.CaptureCheckPage),
  },
  {
    path: 'confirm',
    loadComponent: () => import('./create-confirmation/create-confirmation.page').then((m) => m.CreateConfirmationPage),
  },
  {
    path: 'manually-add-insurance',
    loadComponent: () =>
      import('./manually-add-insurance/manually-add-insurance.page').then((m) => m.ManuallyAddInsurancePage),
  },
  {
    path: 'update/:id',
    loadComponent: () =>
      import('./update-insurance/update-insurance.component').then((m) => m.UpdateInsuranceComponent),
  },
  {
    path: 'update/:id/confirm',
    loadComponent: () => import('./update-confirmation/update-confirmation.page').then((m) => m.UpdateConfirmationPage),
  },
];
