export const getCare = {
  whoWeAreCarringFor: '¿A quién estaremos cuidando?',
  selectTimeThatworks: 'Seleccione el mejor momento para su visita.',
  schedule: 'Horario',
  forms: {
    shareConcerns: 'Por favor comparte tus inquietudes',
  },
  actions: {
    getCare: 'Recibe atención ahora',
    getCareAt: 'Reciba atención hoy a las {{ time }}.',
    selectTime: 'Selecciona el mejor momento para ti',
    submitConcern: 'Enviar inquietud',
    close: 'Cerca',
  },
};
