export const getCare = {
  whoWeAreCarringFor: 'Who will we be caring for?',
  hereToCare: 'We’re here to care for {{ name }}.',
  availability: 'Our providers are available for on-demand care now.',
  availabilityTime: 'Our providers are available at',
  providersAvailable: 'Our providers are available',
  providersAvailableAt: 'Our providers are available at',
  now: 'Now',
  time: '{{ time }} {{ workingDay }}',
  howCanWeHelp: 'Please tell us how we can help.',
  medicalAttention:
    'If this is a medical emergency, seek immediate medical attention at an emergency room or call 911.',
  symptoms: 'Symptoms',
  selectTimeThatworks: 'Please select the best time for your visit.',
  seeMoreTimes: 'See more time slots',
  schedule: 'Schedule',
  youAreSet: 'You’re all set!',
  weSendYouLink: 'We’ll send you a link to join the waiting room online at: ',
  consent: 'Consent',
  registerFor: 'Let’s register for {{ name }}’s visit',
  isGuardianEmployed: 'Are you or another parent or guardian of {{ name }} employed by any of these organizations?',
  partnerInfo: 'Employees of the following partner organizations receive care benefits with us!',
  doesPatientHave: 'Does {{ name }} have a primary care provider we can coordinate with?',
  skipStep: 'If not, feel free to skip this step.',
  signDocuments: 'Please review and sign the documents below.',
  perfect: 'Perfect! You’re fully covered for {{ name }}’s visit.',
  isParentEmployed: 'Is a parent or guardian employed by one of these organizations?',
  providerCoordinate: 'Does {{ name }} have a provider we should coordinate with?',
  benefits: 'Benefits',
  primaryCareProvider: 'Provider',
  addPrimarycareProvider: 'Add a Provider',
  addPatientsPrimaryProvider: 'Add {{ name }}’s Provider',
  reviewPrimaryProvider: 'Please review and confirm {{ name }}’s primary care provider.',
  weAlwaysCare: 'We’ll always care for your kiddos, regardless of your ability to pay!',
  weSupportYou: 'Here’s how we support you:',
  workWithMajorInsurances: 'Work with all major insurances to lower your out-of-pocket costs',
  generousPayment: 'Generous payment assistance',
  standardVisitsStart: 'Standard visits start at $75',
  howWouldYouLikeToContinue: 'How would you like to continue?',
  letsConfirmPhoneNumber:
    'Let’s confirm your phone number below, and we’ll contact you at this number after your visit.',
  everyoneFinancial:
    'Everyone’s financial situation is unique! Our team will reach out to find a payment option that best fits your budget.',
  flexiblePaymentOptions: 'We’ve got flexible payment options available for you.',
  loadingTimes: 'Loading available times...',
  needHelp:
    'Need immediate help? Please dial 911 or head to the nearest emergency room. Otherwise, our support team is ready to assist you!',
  weAreUnavailable: 'Sorry, we’re temporarily unavailable.',
  patientArrived: 'We’re letting our care team know that you’ve arrived...',
  onDemand: 'On demand  care',
  providerReady: 'Your provider is ready to see you, {{ name }}.',
  providerNotified: '{{ name }}, your provider has been notified and will be with you soon!',
  teamNotified: 'We’ll send you a text and email when your clinician arrives – typically within 5 minutes.',
  phyReady: '{{ name }}, is ready for you to join your consultation!',
  virtualVisitToday: '{{ name }}’s visit today',
  thankYouForMeeting: 'Thanks for meeting with us, {{ name }}!',
  needMoreHelp: 'Please don’t hesitate to reach out if you have any questions!',
  closeVisit: 'Are you sure you want to cancel the visit?',
  cancelVisit: 'Cancel Visit',
  fullyCovered: 'You’re fully covered for your visit!',
  partneredWith:
    'We partnered with {{ employer }} to provide employees a benefit that covers the full cost of care provided by Montana Pediatrics.',
  actions: {
    review: 'Review document',
    continue: 'Continue',
    skip: 'Skip',
    addProvider: 'Provider',
    getCare: 'Get Care Now',
    getCareAt: 'Get Care at {{ time }} {{ workingDay }}',
    getCareAtThisTime: 'Get Care at This Time',
    selectTime: 'Select the Best Time for You',
    submitConcern: 'Submit Concern',
    close: 'Close',
    done: 'Done',
    change: 'Change',
    dontSeeProvider: 'I Don’t See My Provider',
    back: 'Back',
    confirmProvider: 'Confirm Provider',
    update: 'Update',
    useMyInsurance: 'Use My Insurance',
    discussPaymentAfter: 'Discuss Payment After Visit',
    confirmStartVisit: 'Confirm & Start Visit',
    confirmScheduleVisit: 'Confirm & Schedule Visit',
    cancelVisit: 'Confirm Cancellation',
    join: 'Join Now',
    contactSupport: 'Contact Support',
    backToHome: 'Back to Home',
  },
  forms: {
    shareConcerns: 'Please share your concerns',
    searchProviders: 'Search providers',
    startTypingProviders: 'Start typing to search for your provider.',
    enterProviderDetails: 'Please enter your provider’s details below.',
    phone: 'Phone',
    phoneNumber: 'Phone number:',
    shareReason: 'Your reasons',
    email: 'Email',
  },
  documents: {
    title: {
      signElectronically: 'Consent For Electronic Signature',
      patientConsent: 'Consent & Financial Policy',
      telemedicineConsent: 'Consent to Participate in Telemedicine Consultation',
      ohca: 'Consent to Notice of Privacy Practices',
    },
    label: {
      signElectronically: 'Yes, I agree to sign documents electronically.',
      patientConsent: 'I understand and agree to the Patient Consent & Financial Policy.',
      telemedicineConsent: 'I understand and consent to participate in telemedicine consultations.',
      ohca: 'I understand and consent to the Notice of Privacy Practices.',
    },
  },
  documentMissing: 'Document is missing!',
  today: 'today',
  tomorrow: 'tomorrow',
  september: 'September',
  underAge21: 'Montana Pediatrics provides on-demand care for all kiddos through the age of 21.',
};
