export const videoCall = {
  defaultInstructions: 'If you blocked access by accident, you can change your choice in the browser settings.',
  getInstructions: 'Get instructions',
  followInstructions: 'Follow the instructions',
  refreshInstructions: 'then refresh this page.',
  joinVisit: 'Join your visit with',
  startVisitWith: 'Start visit with',
  you: 'You',
  interpreterListening: 'Interpreter is listening',
  interpreterSpeaking: 'Interpreter is speaking',
  interpreterInitiating: 'Interpreter is initiating',
  isSpeaking: '{{ name }} is speaking',
  isListening: '{{ name }} is listening',
  youAreListening: 'You are listening',
  preferredLanguage: 'Preferred language:',
  providerLeft: 'has left this visit',
  patientCanceled: 'If your visit was not complete, please wait for your provider to return.',
  rejoinWaitingRoom: 'You can also rejoin the waiting room.',
  actions: {
    visitJoin: 'Join visit now',
    callSupport: 'Call Support',
    connectVideoAudio: 'Connect your video and audio',
    enableCamera: 'Enable camera',
    enableAudio: 'Enable audio',
    instructionLink: 'here',
    allowCamera: 'Allow camera',
    allowMicrophone: 'Allow microphone',
    clickToAllow: 'Click to allow',
    donePrecheck: 'Done video precheck',
    retryCamMic: 'Retry Camera and Mic',
    viewInstructions: 'View instructions here',
    getinstructions: 'Get instructions',
    retryCam: 'Retry Camera',
    retryMic: 'Retry Microphone',
    connecting: 'Connecting',
    translate: 'Translate',
    transcript: 'Transcript',
    audio: 'Audio',
    turnOnTranslation: 'Turn on Translation',
    startVisit: 'Start visit',
    rejoinToRoom: 'Rejoin the waiting room',
  },
  title: {
    patientTrouble: 'Patient is having trouble connecting video and audio',
    patientAware: 'Patient is aware of the issue and provided support.',
    patientLink:
      'If you choose to mark this visit as unable to complete, the patient will be provided a link to rejoin the waiting room.',
    emsTrouble: 'EMS is having trouble connecting video and audio',
    emsAware: 'EMS is aware of the issue and provided support.',
    needHelp: 'Need help? Get support by calling',
    readyToJoin: 'Your visit is ready to join',
    problemCall: 'Having problems? Call ',
    allowToJoin: 'Please allow access to your camera and microphone to join this visit.',
    blockedAccess: 'You have blocked access',
    accessToJoin: 'Access to your camera and microphone is required to join this visit.',
    getHelp: 'Get help to enable access',
    videoPrecheck: 'Set up video and audio',
    cameraStarting: 'Camera is starting',
    AccessToCamMic: 'Access to your camera and microphone is blocked',
    AccesesToMic: 'Access to your microphone is blocked',
    AccesesToCam: 'Access to your camera is blocked',
    NoMicFound: 'No microphone found',
    NoCamFound: 'No camera found',
    allowAccess: 'Please allow access to use your camera and mic for this visit',
    NoCamMicFound: 'No camera and microphone found',
    translationPreferences: 'Translation preferences:',
    translating: 'Translating',
    realTimeTranslation: 'Real-time translation',
    setPreferedLanguage: 'Set your preferred language',
    selectAMicrophone: 'Select a microphone',
    selectASpeaker: 'Select a speaker',
    selectACamera: 'Select a camera',
    setupPermissions: 'Please set up your audio and video.',
  },
  subtitle: {
    AccessToCamMic: 'To join a video visit, please allow access to use your camera and microphone.',
    AccesesToMic: 'To join a video visit, please allow access to use your microphone.',
    AccesesToCam: 'To join a video visit, please allow access to use your camera.',
  },
  forms: {
    videoSource: 'Select video source',
  },
  error: {
    cameraInitializationFailed: 'Camera initialization failed',
  },
};
