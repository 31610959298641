import { Routes } from '@angular/router';

export const personalInformationRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./personal-information-view/personal-information-view.component').then(
        (m) => m.PersonalInformationViewComponent,
      ),
  },
  {
    path: 'edit',
    loadComponent: () =>
      import('./personal-information-edit/personal-information-edit.component').then(
        (m) => m.PersonalInformationEditComponent,
      ),
  },
  {
    path: 'review-changes',
    loadComponent: () =>
      import('./personal-information-review/personal-information-review.component').then(
        (m) => m.PersonalInformationReviewComponent,
      ),
  },
];
