<ion-header [translucent]="true">
  <ion-toolbar
    align-title="center"
    class="text-center px-5 pb-4"
  >
    <ion-buttons slot="start">
      <button
        [attr.aria-label]="'getCare.actions.back' | translate"
        class="btn btn-back d-grid p-0"
        (click)="goBack()"
      >
        <img
          src="assets/back-arrow.svg"
          alt=""
          width="24"
        />
      </button>
    </ion-buttons>
    <ion-title class="px-0">
      <img
        src="assets/mp-logo.svg"
        alt=""
        height="23"
      />
    </ion-title>
  </ion-toolbar>
  <ion-progress-bar
    type="indeterminate"
    [ngClass]="{ 'visibility-invisible': !isLoading }"
  ></ion-progress-bar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid
    fixed
    class="grid d-grid px-5 pt-8 pb-10 min-h-100"
  >
    @if (meta$ | async; as meta) {
      <app-progress-bar
        [width]="meta.progress"
        [label]="meta.label | translate"
      ></app-progress-bar>
      <div class="router-transition">
        <router-outlet></router-outlet>
      </div>
    }
  </ion-grid>
</ion-content>
