export const login = {
  title: {
    seeYou: '¡Qué gusto verte!',
    welcome: 'Bienvenido a Montana Pediatrics.',
    linkSent: 'Te enviamos un enlace para acceder a tu cuenta.',
    getStarted: 'Comencemos conociéndote mejor.',
    contactInfo: '¡Gracias, {{ user }}! Solo falta una cosa antes de terminar.',
    contactSummary: '¿Podrías revisar y confirmar tu información?',
    emailConfirmation: 'Te hemos enviado un correo electrónico para verificar.',
    loginConfirmation: '¡Bienvenido, {{ user }}!',
    infoVerify: '¡Hola! Empecemos por verificar tu información.',
    companyConfirmation: 'Gracias, {{ user }}. Ahora vamos a asegurarnos de que te conectes de manera segura.',
    verifyString: 'Gracias, Melissa. Ahora vamos a asegurarnos de que te conectes de manera segura.',
    searchLanguage: 'Buscar un idioma',
    codeIncorrect: 'Parece que el código que ingresaste es incorrecto.',
    alreadyLinked: 'Parece que el número que ingresaste ya está vinculado a otro paciente.',
    newLinkSent: 'Se ha enviado un nuevo enlace seguro a {{ contact }}',
    didntReceiveLink: '¿No recibiste un nuevo enlace?',
  },
  subtitle: {
    tellUs: '¿Podrías decirnos si eres un paciente nuevo o existente en Next Level?',
    enterEmail:
      'Por favor, ingresa el correo electrónico asociado con tu cuenta. Te enviaremos un enlace para acceder a tu cuenta de Next Level.',
    enterEmailPhone: 'Por favor, ingresa tu número de teléfono o correo electrónico para comenzar.',
    linkSent: 'Tu enlace de inicio de sesión ha sido enviado a {{ value }}.',
    personalInfo:
      'Por favor, proporciona tu información personal y preferencias para configurar una nueva cuenta en Next Level.',
    confirmQuestion: 'Por favor, elige todas las respuestas que correspondan.',
    verifyString: '¿Podrías ingresar la {información que te pedimos confirmar a continuación?}',
    sendCode: 'No te preocupes, con gusto volveremos a enviar el código a {{ email }}.',
    contactUs: 'Si necesitas más ayuda para recuperar tu número de teléfono, no dudes en contactarnos.',
    sendingRequest: 'Estamos enviando tu solicitud',
  },
  content: {
    genderAssigned: 'Género asignado al nacer:',
    name: 'Nombre',
    personalInfo: 'Información personal',
    preferredLanguage: 'Idioma preferido:',
    contactInfo: 'Información de contacto:',
    receivingCommunications: 'Método preferido para iniciar sesión:',
    codeInput: 'Por favor, ingresa el código de 6 dígitos',
    aboutYourself: '¿Podrías tomarte un momento para proporcionar los siguientes detalles?',
  },
  actions: {
    newToNextLevel: 'Soy nuevo en Next Level.',
    nextLevelPatient: 'Soy un paciente de Next Level.',
    continue: 'Continuar',
    resendLink: 'Reenviar enlace',
    linkSent: 'Enlace enviado',
    codeSent: 'Código reenviado!',
    verify: 'Verificar',
    reEnterEmail: 'Reingresar correo electrónico',
    reEnterPhoneNumber: 'Reingresar número de teléfono',
    resendCode: 'Reenviar código',
    contactUs: 'Contáctanos',
    differentCredential: 'Usar credencial diferente',
    done: 'Hecho',
    getStarted: 'Comenzar',
    clearInput: 'Borrar entrada',
  },
  forms: {
    email: 'Correo electrónico',
    emailPhone: 'Número de teléfono o correo electrónico',
    firstName: 'Nombre',
    lastName: 'Apellido',
    dob: 'Fecha de nacimiento',
    preferredLanguage: 'Configura tu idioma preferido',
    genderAssigned: 'Tu género asignado al nacer:',
    male: 'Masculino',
    female: 'Femenino',
    noAnswer: 'Prefiero no responder',
    phoneNum: 'Número de teléfono',
    streetAddress: 'Dirección',
    aptNum: 'Apartamento, suite, etc.',
    city: 'Ciudad',
    state: 'Estado',
    zipCode: 'Código postal',
    textMessages: 'Mensajes de texto',
    both: 'Ambos, mensaje de texto y correo electrónico',
    communications: '¿Cómo te gustaría recibir las comunicaciones?',
    incorrectCode: 'Código incorrecto',
  },
};
