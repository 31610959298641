<ion-header [translucent]="true">
  <ion-toolbar
    align-title="center"
    class="text-center px-5 pb-4"
  >
    <ion-buttons slot="start">
      <ion-back-button
        text=""
        icon="assets/back-arrow.svg"
        [defaultHref]="backUrl"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="px-0">
      <img
        src="assets/mp-logo.svg"
        alt=""
        height="23"
      />
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-grid
    fixed
    class="grid d-grid px-5 py-6 min-h-100"
  >
    @if (meta$ | async; as meta) {
      <app-progress-bar
        [width]="meta.progress"
        [label]="meta.label | translate"
      ></app-progress-bar>
      <div class="router-transition">
        <router-outlet></router-outlet>
      </div>
    }
  </ion-grid>
</ion-content>
