export const registration = {
  title: {
    provideMoreInfo: 'Gracias, {{ name }}! ¿Podrías contarnos un poco más sobre ti?',
    contactSummary: '¿Podrías revisar y confirmar tu información?',
    emailConfirmation: 'Te hemos enviado un correo electrónico para verificar.',
    phoneConfirmation: '¡Te hemos enviado un código!',
    loginConfirmation: 'Estamos encantados de tenerte, {{ user }}.',
    infoVerify: 'Comencemos verificando tu información.',
    pleaseEnterCode: 'Ingrese el código de 6 dígitos enviado a {{ value }} para verificar sus datos de contacto.',
    companyConfirmation: 'Para ayudar a mantener tu información segura, ¿podrías verificar lo siguiente?',
    verifyContact: 'Terminemos agregando tu {{ contact }} a tu cuenta.',
    verifyFlexibility:
      'La próxima vez que inicies sesión, tendrás la flexibilidad de usar tanto tu número de teléfono como tu correo electrónico.',
    searchLanguage: 'Buscar un idioma',
    codeIncorrect: 'El código que ingresaste no se puede verificar.',
    alreadyLinked: 'Parece que tu {{ contact }} está conectado a otra cuenta.',
    accountMatched: 'Gracias, {{ user }}! Ahora vamos a asegurarnos de que te conectes de manera segura.',
    weAreHavingTrouble: 'Estamos teniendo problemas para crear o acceder a tu cuenta...',
    addMissingAddress: '¿Podrías agregar una dirección a tu cuenta?',
    dobVerification: 'Asegurémonos de que te conectes de manera segura.',
    phoneNum: 'número de teléfono',
    contactConnected: 'El {{ contact }} que ingresaste está vinculado a otro perfil.',
  },
  subtitle: {
    enterEmail:
      'Por favor, ingresa el correo electrónico asociado con tu cuenta. Te enviaremos un enlace para acceder a tu cuenta de Next Level.',
    enterEmailPhone: 'Por favor, ingresa tu número de teléfono o correo electrónico para comenzar.',
    linkSent: 'Tu enlace de inicio de sesión ha sido enviado a {{ value }}.',
    verifyEmail: 'Por favor, ingresa el {{ type }} que usas para iniciar sesión en la aplicación Next Level.',
    verifyPhone: 'Por favor, ingresa el número de teléfono que usas para iniciar sesión en la aplicación Next Level.',
    sendCode: 'No te preocupes, con gusto volveremos a enviar el código a {{ contact }}.',
    contactUs: 'Por favor, inicia sesión con los detalles correctos o contáctanos para obtener ayuda.',
    accountMatched:
      'Por favor, ingresa el correo electrónico que usaste anteriormente para iniciar sesión en la aplicación Next Level.',
    ourTeamIsHere:
      'Nuestro equipo en Next Level está aquí para ayudarte a crear o acceder a tu cuenta para que puedas usar la aplicación Next Level.',
    dobVerification: '¿Podrías verificar tu fecha de nacimiento?',
    singIntoExistingContact:
      'Puedes iniciar sesión en el perfil existente o usar un {{ contact }} diferente para crear un nuevo perfil.',
  },
  content: {
    genderAssigned: 'Tu género asignado al nacer:',
    name: 'Nombre',
    address: 'Dirección',
    personalInfo: 'Información personal',
    preferredLanguage: 'Idioma preferido',
    contactInfo: 'Información de contacto:',
    codeInputPhone: 'Por favor, verifica tu número de teléfono ingresando el código de 6 dígitos.',
    codeInputEmail: 'Por favor, verifica tu correo electrónico ingresando el código de 6 dígitos.',
    aboutYourself: 'Por favor, tómate un momento para compartir algunos detalles sobre ti.',
    loginMethod: 'Actualmente estamos utilizando el siguiente {{ loginMethod }} para iniciar sesión en tu cuenta.',
  },
  actions: {
    continue: 'Continuar',
    confirm: 'Confirmar',
    resendLink: 'Reenviar enlace',
    verify: 'Verificar',
    reEnterEmail: 'Reingresar correo electrónico',
    reEnterPhoneNumber: 'Reingresar número de teléfono',
    resendCode: 'Enviar nuevo código',
    changeEmail: 'Cambiar correo electrónico',
    changePhone: 'Cambiar número de teléfono',
    contactUs: 'Contáctanos',
    differentCredential: 'Usar credencial diferente',
    signInAgain: 'Iniciar sesión nuevamente',
    startChat: 'Iniciar un chat',
    signIntoProfile: 'Iniciar sesión en este perfil',
    enterDifferentContact: 'Ingresar un {{ contact }} diferente',
  },
  forms: {
    email: 'Correo electrónico',
    emailPhone: 'Número de teléfono o correo electrónico',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    dob: 'Fecha de nacimiento',
    dobPlaceholder: 'MM-DD-YYYY',
    genderAssigned: 'Tu género asignado al nacer:',
    male: 'Masculino',
    female: 'Femenino',
    noAnswer: 'Prefiero no responder',
    phoneNum: 'Número de teléfono',
    phone: 'Número de teléfono',
    streetAddress: 'Dirección',
    aptNum: 'Apto, suite, etc.',
    city: 'Ciudad',
    state: 'Estado',
    zipCode: 'Código postal',
    textMessages: 'Mensajes de texto',
    both: 'Ambos, mensaje de texto y correo electrónico',
  },
  email: 'correo electrónico',
  phone: 'teléfono',
  error: {
    laguageRequired: 'Por favor, selecciona el idioma preferido',
    stateRequired: 'Por favor, selecciona el estado',
  },
};
