import { Component } from '@angular/core';
import { RegistrationService } from '../../services/registration.service';
import { IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
  selector: 'app-home-base',
  template: '<ion-router-outlet></ion-router-outlet>',
  imports: [IonRouterOutlet],
  providers: [RegistrationService],
  standalone: true,
})
export class HomeBaseComponent {}
