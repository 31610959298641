import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnTranslateService extends TranslateService {
  override instant(key: string | string[], interpolateParams?: Record<string, any>): string | any {
    const translation = super.instant(key, interpolateParams);

    return this.processTranslation(translation, interpolateParams);
  }

  override get(key: string | string[], interpolateParams?: Record<string, any>): Observable<string | any> {
    return super.get(key, interpolateParams).pipe(
      map((translation) => {
        return this.processTranslation(translation, interpolateParams);
      }),
    );
  }

  processTranslation(text: string, params?: Record<string, any>): string {
    if (!params || !Object.values(params).some(Boolean)) return text;
    return /\b\w+’s\b/.test(text) ? this.fixPossessive(text) : text;
  }

  private fixPossessive(text: string): string {
    return text.replace(/\b(\w+s)’s\b/g, '$1’');
  }
}
