export const notification = {
  upcomingVisit: 'Próxima visita',
  labResults: 'Resultados de laboratorio',
  getCare: 'Obtener atención',
  prescriptionSavings: 'Ahorros en recetas',

  getDirections: 'Obtener direcciones',
  addToCalendar: 'Agregar a mi calendario',
  cancel: 'Cancelar',
  cbc: 'Hemograma completo',
  metabolic: 'Metabólico completo...',
  inPersonCare: 'Atención en persona',
  virtualCare: 'Atención virtual',
  skipAVisit: 'Omitir una visita',
  findPrescriptions: 'Encontrar tus recetas',
};
