export const checkout = {
  orders: 'Your orders',
  laboratoryTest: 'Laboratory Tests',
  yourOrders: 'Here are your orders we’re requesting payment for today.',
  subtotal: 'Subtotal',
  total: 'Total',
  thankYou: 'Thank you, {{ name }}!',
  paymentConfirmation: 'We’ve sent the payment confirmation to your email.',
  actions: {
    payApple: 'Pay with',
    payCard: 'Pay with Credit Card',
    payNow: 'Pay now',
  },
  forms: {
    cardNumber: 'Card number',
    expirationDate: 'Expiration date (MM/YY)',
    ccv: 'CCV',
    name: 'Name',
    lastName: 'Last name',
  },
};
