export const getCare = {
  whoWeAreCarringFor: 'Who will we be caring for?',
  hereToCare: 'We’re here to care for {{ name }}.',
  availability: 'Our providers are available for on-demand care now.',
  availabilityTime: 'Our providers are available at',
  time: '{{ time }} {{ workingDay }}',
  howCanWeHelp: 'Please tell us how we can help.',
  medicalAttention:
    'If this is a medical emergency, seek immediate medical attention at an emergency room or call 911.',
  symptoms: 'Symptoms',
  selectTimeThatworks: 'Please select the best time for your visit.',
  seeMoreTimes: 'See more time slots',
  schedule: 'Schedule',
  youAreSet: 'You’re all set!',
  weSendYouLink: 'We’ll send you a link to join the waiting room online at: ',
  consent: 'Consent',
  registerFor: 'Let’s register for {{ name }}’s visit',
  skipStep: 'If not, feel free to skip this step.',
  signDocuments: 'Please review and sign the documents below.',
  perfect: 'Perfect! You’re fully covered for {{ name }}’s visit.',
  isParentEmployed: 'Is a parent or guardian employed by one of these organizations?',
  providerCoordinate: 'Does {{ name }} have a primary care provider we should coordinate with?',
  wePartnered:
    'We partnered with {{ provider }} to provide employees a benefit that covers the full cost of care provided by Montana Pediatrics.',
  benefits: 'Benefits',
  primaryCareProvider: 'Primary Care Provider',
  addPrimarycareProvider: 'Add a Primary Care Provider',
  addPatientsPrimaryProvider: 'Add {{ name }}’s Primary Care Provider',
  reviewPrimaryProvider: 'Please review confirm {{ name }}’s primary care provider.',
  weAlwaysCare: 'We’ll always care for your kiddos, regardless of your ability to pay!',
  weSupportYou: 'Here’s how we support you:',
  workWithMajorInsurances: 'Work with all major insurances to lower your out-of-pocket costs',
  generousPayment: 'Generous payment assistance',
  standardVisitsStart: 'Standard visits start at $75',
  howWouldYouLikeToContinue: 'How would you like to continue?',
  letsConfirmPhoneNumber:
    'Let’s confirm your phone number below, and we’ll contact you at this number after your visit.',
  everyoneFinancial:
    'Everyone’s financial situation is unique! Our team will reach out to find a payment option that best fits your budget.',
  flexiblePaymentOptions: 'We’ve got flexible payment options available for you.',
  loadingTimes: 'Loading available times...',
  needHelp:
    'Need immediate help? Please dial 911 or head to the nearest emergency room. Otherwise, our support team is ready to assist you!',
  weAreUnavailable: 'Sorry, we’re temporarily unavailable.',
  patientArrived: 'We’re letting our care team know that you’ve arrived...',
  onDemand: 'On demand  care',
  providerReady: 'Your provider is ready to see you, {{ name }}.',
  providerNotified: '{{ name }}, your provider has been notified and will be with you soon!',
  teamNotified: 'We’ll send you a text and email when your clinician arrives – typically within 5 minutes.',
  phyReady: '{{ name }}, DNP is ready for you to join your consultation!',
  virtualVisitToday: '{{ name }}’s visit today',
  thankYouForMeeting: 'Thanks for meeting with us, {{ name }}!',
  needMoreHelp: 'Need more help? Just reach out to our support team! Feel free to close this window when you’re done.',
  closePage: 'Please feel free to close this page',
  closeVisit: 'Are you sure you want to cancel the visit?',
  cancelVisit: 'Cancel Visit',
  actions: {
    review: 'Review document',
    continue: 'Continue',
    skip: 'Skip',
    addProvider: 'Add Provider',
    getCare: 'Get care now',
    getCareAt: 'Get care at {{ time }} {{ workingDay }}.',
    getCareAtThisTime: 'Get care at this time',
    selectTime: 'Select the best time for you',
    submitConcern: 'Submit concern',
    close: 'Close',
    done: 'Done',
    change: 'Change',
    dontSeeProvider: 'I don’t see my provider',
    back: 'Back',
    confirmProvider: 'Confirm provider',
    update: 'Update',
    useMyInsurance: 'Use my insurance',
    discussPaymentAfter: 'Discuss payment after visit',
    confirmStartVisit: 'Confirm & start visit',
    cancelVisit: 'Confirm cancellation',
    join: 'Join Now',
    contactSupport: 'Contact Support',
    backToHome: 'Back to Home',
  },
  forms: {
    shareConcerns: 'Please share your concerns',
    searchProviders: 'Search providers',
    startTypingProviders: 'Start typing to search for your primary care provider.',
    enterProviderDetails: 'Please enter your provider’s details below.',
    phone: 'Phone',
    phoneNumber: 'Phone number:',
    shareReason: 'Your reasons',
  },
  today: 'today',
  tomorrow: 'tomorrow',
  september: 'September',
};
