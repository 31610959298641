export const account = {
  manage: 'Manage my account',
  profileManage: 'Who’s profile would you like to manage?',
  logout: 'Log out',
  update: 'Update',
  name: 'Name',
  lastName: 'Last name',
  firstName: 'First name',
  dob: 'Date of birth',
  address: 'Address',
  gender: 'Assigned sex at birth',
  yourGender: 'Assigned sex at birth',
  preferredLanguage: 'Preferred language',
  yourPreferredLanguage: 'Your preferred language',
  male: 'Male',
  female: 'Female',
  preferNotToAnswer: 'I prefer not to answer',
  addNew: 'Add a new person',
  inviteNewPerson: 'Invite a new person',
  confirm: 'Confirm',
  edit: 'Edit',
  review: 'Review',
  patientProfile: 'Profile',
  personalInformation: 'Personal information',
  contactInfo: 'Contact information',
  reviewPersonalInfo: 'Could you please review and confirm your information?',
  setPreferredLanguage: 'Set your preferred language',
  currentlyUsing: 'We are currently using the following phone number and email to log into your account.',
  updatePhone: 'Update your phone number.',
  updateEmail: 'Update your email.',
  phoneUpdated: 'Your phone number has been successfully updated!',
  emailUpdated: 'Your email has been successfully updated!',
  emailCredential: 'We will be using the following email as your login credential.',
  phoneCredential: 'We will be using the following phone number as your login credential.',
  reviewEmail: 'Could you please review and confirm your email?',
  reviewPhone: 'Could you please review and confirm your phone number?',
  reviewAddress: 'Could you please review and confirm your address?',
  digitCode: 'Please enter the 6-digit code.',
  emailConfirmation: 'We’ve sent you an email to verify.',
  phoneConfirmation: 'We’ve sent you an sms to verify.',
  addPhoto: 'Add profile photo',
  updateIssue: 'We’re having an issue updating your account.',
  getInTouch:
    'Our team is here to support you. Don’t hesitate to get in touch with us to request this update to your patient information.',
  startChat: 'Start chat',
  userDisconnected: '{{ name }} has been disconnected from your account.',
  healthInsurance: 'Your health insurance',
  makeUpdateInsurance: 'Please feel free to make any updates to your current insurance or add other coverage.',
  addOtherInsurance: 'Add Other Insurance',
  insuranceCarrier: 'Insurance carrier',
  planNumber: 'Plan number',
  couldYouReviewInsurance: 'Could you please review and confirm your insurance information?',
  setInsuranceCarrier: 'Search for insurance carrier',
  nicePhoto: 'Nice photo!',
  perfectLetsFinish: 'Perfect! Let’s finish up.',
  couldYouPleaseConfirm: 'Could you please confirm the following information?',
  couldYouPleaseEnter: 'Could you please enter the following information?',
  insuranceCard: '{{ side }} of your card',
  addInsurane: 'Let’s add your insurance information.',
  positionCard: 'Position your insurance card inside this frame.',
  groupName: 'Group Name',
  groupNumber: 'Group Number',
  relationship: 'Relationship',
  effectiveDate: 'Effective Date',

  personalDetails: 'Personal details',
  insurance: 'Insurance information',
  primaryProvider: 'Primary care provider',
  changeImg: 'Change profile photo',
  phoneNum: 'phone number',
  email: 'email',
  bestCare: 'Let`s figure out the best level of care for {{ name }}, together.',
  noSearchResults: 'No results.',
  call: 'Call',
  support: 'Support',

  connectedAccounts: 'Connected accounts',
  accounts: 'Accounts',
  manageAccounts: 'Manage connected accounts',

  healthcareSupport: 'Healthcare support',
  partnerBenefits: 'Partner benefits',
  primaryCareProvider: 'Primary care provider',
  logOut: 'Log Out',
  changePrimaryAccoun: 'Change Primary Account',
  backToAccounts: 'Back to Account',
  backToConnectedAccounts: 'Back to Connected Accounts',
  chnagedAccount: 'We’re all set! {{ name }} is now the primary account.',
  cancel: 'Cancel',
  transferOver: 'All the current accounts associated with you will transfer over to {{ name }}.',
  changeAccount: 'Change the primary account to {{ name }}?',
  addInsurance: 'Add Insurance',
  noInsurance: 'We currently don’t have any insurance on file.',
  updateInsurance: 'Please update your insurance information.',
  connectedSet: 'We’re all set! You’re connected to {{ name }}’s patient account.',
  noConnected: 'Currently, there are no accounts with access to {{ name }}.',
  memberName: 'Member name',
  insuranceLoading: 'We’re analyzing your insurance information now!',
  weDontHavePartnerBenefits: 'We don’t have your partner benefits on file.',
  yourPartnerBenefits: 'Your partner benefits',
  greatNews: 'Great news! We’ve partnered with {partner organization} for all your care costs to be covered in full.',
  doYouWorkWith: 'Do you work with any of our partner organizations?',
  notCoveredConfirmation:
    'We’re currently partnering with select organizations to provide care benefits. If your employer isn’t listed or you have questions, please feel free to contact us for assistance!',
  notCoveredTitle: 'It looks like you’re not covered under our partner benefits.',
  fullCoveredConfirmation:
    'We’ve partnered with {{ orgName }} so your visits with Montana Pediatrics are fully covered—at no extra cost to you!',
  fullyCoveredTitle: 'Great news! Your visits are fully covered!',
  weDontHaveProviders: 'We currently do not have any primary care providers on file.',
  yourPrimaryCareProvider: 'Your primary care provider',
  actions: {
    edit: 'Edit',
    confirm: 'Confirm',
    verify: 'Verify',
    cancel: 'Cancel',
    updateEmail: 'Update email',
    updatePhone: 'Update phone number',
    backToContact: 'Back to Contact Information',
    download: 'Download',
    change: 'Change',
    goBack: 'Go Back',
    review: 'Review',
    update: 'Update',
    continue: 'Continue',
    disconnect: 'Disconnect',
    cancelRequest: 'Cancel Request',
    yesSendRequest: 'Yes, send request to access.',
    noDoNotRequest: 'No, do not request to access.',
    acceptRequest: 'Accept Request',
    denyRequest: 'Deny Request',
    upload: 'Upload',
    disconnectAccess: 'Disconnect Access',
    takePhoto: 'Take a photo of my insurance card.',
    manuallyAdd: 'Manually enter my insurance information.',
    addNewPerson: 'Add new person',
    editInsuranceInfo: 'Edit insurance info',
    checkYourBenefits: 'Check your benefits',
    goBackToAccount: 'Go back to account',
    contactUs: 'Contact us',
    addProvider: 'Add provider',
    addAnotherProvider: 'Add another provider',
  },
  preferredInusrance: 'Preferred insurance',
  chooseInsurance: 'Look up your insurance.',
  reviewInsurance: 'Please review and confirm your Insurance.',
  noReportedIssue: 'We currently do not have any recently reported issues.',
  haveOtherCoverage: 'Yes, I have other coverage.',
  dontHaveOtherCoverage: 'No, I don’t have other coverage.',
  selectRelationship: 'Select relationship',

  peopleWithAccess: 'People with access to {{ name }}’s account.',
  youCanManageAccess: 'You can manage who has access to your account and control what information they can see.',
  sharePersonDetails: 'Please share with us the details of the person you wish to add.',
  relationshipTo: 'Relationship to you:',
  relationshipToPatient: 'Relationship to the patient:',
  pleasereviewAndConfirmToSend: 'Could you please review and confirm  {{ name }}’s information?',
  invitationSent: 'Your request to connect and access {{ name }}’s account has been sent!',
  weWillNotifyYou: 'We will notify you once it has been accepted.',
  permissions: 'Permissions',
  transferAccess: 'Transfer Access',
  toGrantPrimaryAccess:
    'To grant primary access to {{ name }}’s patient account, please provide a phone number or email address that {{ name }} has secure and private access to.',
  enterNewEmailPhone: 'Enter new phone number or email',
  transferRequestSent: 'Your transfer request has been sent to {{ contact }}.',
  feelFreeToCancelrequest: 'Feel free to cancel this request until the transfer has been confirmed.',
  confirmAuthority: 'I am authorized to seek medical care for this patient.',
  weNoticedThatPatient: 'We noticed that {{ name }} already has a patient account with us.',
  wouldYouLikeRequestAccess: 'Would you like to request authorized access to their patient account?',
  requestingAccessTo: '{{ name }} is requesting authorized access to {{ patientName }}’s patient account.',
  reviewRequesterDetails: 'Please review the requester’s details and decide whether to accept or deny the request.',
  requester: 'Requester',
  uploadYourPhoto: 'Would you like to upload a profile photo?',
  permissionsCard:
    '{{ name }}’s account is configured to use the same phone number as {{ connectedName }} at {{ number }}, providing full access.',
  weAreAllSet: 'We’re all set! {{ name }} is now connected to your account.',
  successfullyDenied:
    'We have successfully denied {{ requesterName }}’s request to access {{ approverName }}’s patient profile.',
  pleaseFeelFreeToClose: 'Please feel free to close this browser to exit.',
  confirmDisconnect: 'Please confirm that you want to disconnect {{ name }} from your account.',
  newAccessToAccount: 'Hi {{ approver }}, the following {{ contactType }} has been added to your account: {{contact }}',
  verifyDob: 'To help keep your information secure, could you please verify your date of birth.',
  viewMedicalRecords: 'View medical records',
  viewAppointmentHistory: 'View appointment history',
  scheduleCare: 'Schedule care',
  viewAppointmentSchedule: 'View appointment schedule',
  dobCantVerify: 'The date of birth you entered cannot be verified.',
  dobCantVerifySubtitle:
    'Our team at Next Level is here to support you. If you believe this is a mistake, we’ll be happy to help you get access to the Next Level app.',
  relations: {
    grandparent: 'Grandparent',
    childDependent: 'Child/dependent',
    spouse: 'Spouse',
    friend: 'Friend',
    primaryAccount: 'Primary account',
    parent: 'Parent/guardian',
    other: 'Other',
    child: 'Child',
    self: 'Self',
    dependent: 'Dependent',
  },
  pronouns: {
    she: 'She/her/hers',
    he: 'He/him/his',
    they: 'They/them/theirs',
    no_disclose: 'I prefer not to disclose',
  },
  transferCanceled: 'The transfer of primary account access from {{ requester }} to {{ approver }} has been canceled.',
  transferAccepted: '{{ approver }}’s account access has been successfully transferred to {{ contact }}.',
  accessRemoved: '{{ requester }}’s access has been removed.',
  PolicyholderLastName: 'Policy holder last name',
  PolicyholderFirstName: 'Policy holder first name',
  MemberId: 'Member id',
  CarrierName: 'Carrier name',
  PlanNumber: 'Plan number',
  GroupNumber: 'Group number',
  GroupName: 'Group name',
  pending: 'Pending',
};
