export const login = {
  title: {
    seeYou: 'It’s so nice to see you!',
    welcome: 'Welcome to Montana Pediatrics.',
    linkSent: ' We sent you a link to sign into your account.',
    getStarted: 'Let’s get started by getting to know you better.',
    contactInfo: 'Thank you, {{ user }}! Just one more thing before we finish up.',
    contactSummary: 'Could you please review and confirm your information?',
    emailConfirmation: 'We’ve sent you an email to verify.',
    loginConfirmation: 'Welcome {{ user }}!',
    infoVerify: 'Hey there! Let’s start by verifying your information.',
    companyConfirmation: 'Thank you, {{ user }}. Now let’s make sure we get you logged in securely.',
    verifyString: 'Thank you, Melissa! Now let’s make sure you get securely logged in.',
    searchLanguage: 'Search for a language',
    codeIncorrect: 'It looks like the code you entered is incorrect.',
    alreadyLinked: 'It seems that the number you entered is already linked to another patient.',
    newLinkSent: 'A new secure link has been sent to {{ contact }}',
    didntReceiveLink: 'Didn’t receive a new link?',
  },
  subtitle: {
    tellUs: 'Could you please tell us if you’re a new or existing patient with Next Level?',
    enterEmail:
      'Please enter the email associated with your account. We’ll send you a link to sign in to your Next Level account.',
    enterEmailPhone: 'Please enter your phone number or email to get started.',
    linkSent: 'Your sign-in link has been sent to {{ value }}.',
    personalInfo: 'Please tell us your personal information and preferences to set up a new Next Level account.',
    confirmQuestion: 'Please choose all the answers that apply.',
    verifyString: 'Could you please input the {info we’re asking you to confirm below?}',
    sendCode: 'Don’t worry, we’d be happy to send the code again to {{ email }}.',
    contactUs:
      'If you require further assistance in retrieving your phone number, please do not hesitate to contact us.',
    sendingRequest: 'We are sending your request',
    verifying: 'Verifying',
    verification: 'Verification',
    getStarted: 'Get started',
  },
  content: {
    genderAssigned: 'Gender assigned at birth:',
    name: 'Name',
    personalInfo: 'Personal information',
    preferredLanguage: 'Preferred language:',
    contactInfo: 'Contact information:',
    receivingCommunications: 'Preferred log-in method:',
    codeInput: 'Please enter the 6-digit code',
    aboutYourself: 'Could you please take a moment to provide the following details?',
  },
  actions: {
    newToNextLevel: 'I’m New to Next Level',
    nextLevelPatient: 'I’m a Next Level Patient',
    continue: 'Continue',
    resendLink: 'Resend Link',
    linkSent: 'Link Sent',
    codeSent: 'Code Resent!',
    verify: 'Verify',
    reEnterEmail: 'Re-enter Email',
    reEnterPhoneNumber: 'Re-enter Phone Number',
    resendCode: 'Resend Code',
    contactUs: 'Contact Us',
    differentCredential: 'Use Different Credential',
    done: 'Done',
    getStarted: 'Get Started',
    clearInput: 'Clear Input',
  },
  forms: {
    email: 'Email',
    emailPhone: 'Phone number or email',
    firstName: 'First name',
    lastName: 'Last name',
    dob: 'Date of birth',
    preferredLanguage: 'Set your preferred language',
    genderAssigned: 'Your gender assigned at birth:',
    male: 'Male',
    female: 'Female',
    noAnswer: 'I prefer not to answer',
    phoneNum: 'Phone number',
    streetAddress: 'Address',
    aptNum: 'Apt, suite, etc',
    city: 'City',
    state: 'State',
    zipCode: 'ZIP code',
    textMessages: 'Text messages',
    both: 'Both text message and email',
    communications: 'How would you like to receive communications?',
    incorrectCode: 'Please enter a valid code.',
    expiredCode: 'Code expired, please resend.',
  },
};
