import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EnTranslateService } from './en-translate.service';

@Injectable({
  providedIn: 'root',
})
export class OllaTranslateService extends TranslateService {
  enTranslateService = inject(EnTranslateService);

  override instant(key: string | string[], interpolateParams?: Record<string, any>): string | any {
    if (this.currentLang === 'en') return this.enTranslateService.instant(key, interpolateParams);

    return super.instant(key, interpolateParams);
  }

  override get(key: string | string[], interpolateParams?: Record<string, any>): Observable<string | any> {
    if (this.currentLang === 'en') return this.enTranslateService.get(key, interpolateParams);

    return super.get(key, interpolateParams);
  }
}
