export const home = {
  greeting: 'Buenos días, {{name}}',
  title: 'Gracias por confiar en nosotros para tu atención.',
  watch: 'Ver',
  home: 'Inicio',
  care: 'Asistencia',
  support: 'Apoyar',
  account: 'Cuenta',
  updates: '¡Estamos obteniendo nuevas actualizaciones para ti ahora!',
  ics: {
    virtualAppointment: 'Cita virtual',
    urgentCareWalkIn: 'Atención urgente sin cita previa',
    urgentCareAppointment: 'Cita de atención urgente',
    nextLevelUrgentCare: 'Atención urgente de Next Level',
  },
};
