import { Routes } from '@angular/router';

export const connectedAccountsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
  {
    path: 'list',
    loadComponent: () => import('./accounts-list/accounts-list.page').then((m) => m.AccountsListPage),
  },
  {
    path: 'account',
    loadComponent: () => import('./connected-account/connected-account.page').then((m) => m.ConnectedAccountPage),
  },
  {
    path: 'account/transfer-access',
    loadComponent: () => import('./transfer-access/transfer-access.page').then((m) => m.TransferAccessPage),
  },
  {
    path: 'account/transfer-access/request/:assocId',
    loadComponent: () => import('./transfer-request/transfer-request.page').then((m) => m.TransferRequestPage),
  },
  {
    path: 'account/disconnect',
    loadComponent: () => import('./disconnect-account/disconnect-account.page').then((m) => m.DisconnectAccountPage),
  },
  {
    path: 'account/disconnect/confirm',
    loadComponent: () => import('./disconnect-confirm/disconnect-confirm.page').then((m) => m.DisconnectConfirmPage),
  },
];
