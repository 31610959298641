<ion-tabs
  [ngClass]="{ 'tab-loading': isLoading }"
  (ionTabsWillChange)="onTabChange($event)"
  (ionTabsDidChange)="onTabChanged()"
>
  <ion-tab-bar
    [hidden]="!showTabBar"
    slot="bottom"
    [translucent]="true"
    [ngClass]="platformReady && currentPlatform === 'ios' ? 'pb-8' : 'pb-5'"
    class="pt-5"
  >
    @for (tab of tabs; track tab) {
      <ion-tab-button
        [tab]="tab.tab"
        class="fs-13"
      >
        <span class="{{ tab.icon }} icon fs-32"></span>
        <div class="text text-trim-1 fw-600">{{ tab.label | translate }}</div>
      </ion-tab-button>
    }
  </ion-tab-bar>
</ion-tabs>
