export const account = {
  manage: 'Gestionar mi cuenta',
  logout: 'Cerrar sesión',
  update: 'Actualizar',
  name: 'Nombre',
  lastName: 'Apellido',
  firstName: 'Primer nombre',
  dob: 'Fecha de nacimiento',
  address: 'Dirección',
  gender: 'Sexo asignado al nacer',
  yourGender: 'Sexo asignado al nacer',
  preferredLanguage: 'Idioma preferido',
  yourPreferredLanguage: 'Tu idioma preferido',
  male: 'Masculino',
  female: 'Femenino',
  preferNotToAnswer: 'Prefiero no responder',
  addNew: 'Agregar una nueva persona',
  inviteNewPerson: 'Invitar a una nueva persona',
  confirm: 'Confirmar',
  edit: 'Editar',
  review: 'Revisar',
  patientProfile: 'Perfil',
  personalInformation: 'Información personal',
  contactInfo: 'Información de contacto',
  reviewPersonalInfo: '¿Podrías revisar y confirmar tu información, por favor?',
  setPreferredLanguage: 'Configura tu idioma preferido',
  currentlyUsing:
    'Actualmente estamos usando el siguiente número de teléfono y correo electrónico para iniciar sesión en tu cuenta.',
  updatePhone: 'Actualiza tu número de teléfono.',
  updateEmail: 'Actualiza tu correo electrónico.',
  phoneUpdated: '¡Tu número de teléfono se ha actualizado con éxito!',
  emailUpdated: '¡Tu correo electrónico se ha actualizado con éxito!',
  emailCredential: 'Usaremos el siguiente correo electrónico como tu credencial de inicio de sesión.',
  phoneCredential: 'Usaremos el siguiente número de teléfono como tu credencial de inicio de sesión.',
  reviewEmail: '¿Podrías revisar y confirmar tu correo electrónico, por favor?',
  reviewPhone: '¿Podrías revisar y confirmar tu número de teléfono, por favor?',
  reviewAddress: '¿Podrías revisar y confirmar tu dirección, por favor?',
  digitCode: 'Por favor, ingresa el código de 6 dígitos.',
  emailConfirmation: 'Te hemos enviado un correo electrónico para verificar.',
  phoneConfirmation: 'Te hemos enviado un SMS para verificar.',
  addPhoto: 'Agregar foto de perfil',
  updateIssue: 'Estamos teniendo un problema al actualizar tu cuenta.',
  getInTouch:
    'Nuestro equipo está aquí para apoyarte. No dudes en ponerte en contacto con nosotros para solicitar esta actualización en tu información de paciente.',
  startChat: 'Iniciar chat',
  userDisconnected: '{{ name }} se ha desconectado de tu cuenta.',
  healthInsurance: 'Tu seguro de salud',
  makeUpdateInsurance: 'Siéntete libre de hacer cualquier actualización a tu seguro actual o agregar otra cobertura.',
  addOtherInsurance: 'Agregar otro seguro',
  insuranceCarrier: 'Aseguradora',
  planNumber: 'Número de plan',
  couldYouReviewInsurance: '¿Podrías revisar y confirmar la información de tu seguro, por favor?',
  setInsuranceCarrier: 'Buscar aseguradora',
  nicePhoto: '¡Buena foto!',
  perfectLetsFinish: '¡Perfecto! Vamos a terminar.',
  couldYouPleaseConfirm: '¿Podrías confirmar la siguiente información, por favor?',
  couldYouTellAboutInsurance: '¿Podrías contarnos algo sobre tu seguro, por favor?',
  insuranceCard: '{{ side }} de tu tarjeta',
  addInsurane: 'Agreguemos la información de tu seguro.',
  positionCard: 'Coloca tu tarjeta de seguro dentro de este marco.',
  groupName: 'Nombre del grupo',
  groupNumber: 'Número de grupo',
  relationship: 'Relación',
  effectiveDate: 'Fecha de vigencia',

  personalDetails: 'Detalles personales',
  insurance: 'Información del seguro',
  primaryProvider: 'Proveedor de atención primaria',
  changeImg: 'Cambiar foto de perfil',
  phoneNum: 'Número de teléfono',
  email: 'Correo electrónico',
  bestCare: 'Vamos a determinar el mejor nivel de atención para {{ name }} juntos.',
  noSearchResults: 'No hay resultados.',

  connectedAccounts: 'Perfiles conectados',
  accounts: 'Cuentas',
  manageAccounts: 'Gestionar cuentas conectadas',

  healthcareSupport: 'Soporte de atención médica',
  partnerBenefits: 'Beneficios para la pareja',
  provider: 'Proveedor',
  logOut: 'Cerrar sesión',
  changePrimaryAccoun: 'Cambiar cuenta principal',
  backToAccounts: 'Volver a la cuenta',
  backToConnectedAccounts: 'Volver a cuentas conectadas',
  chnagedAccount: '¡Todo listo! {{ name }} es ahora la cuenta principal.',
  cancel: 'Cancelar',
  transferOver: 'Todas las cuentas actuales asociadas a ti se transferirán a {{ name }}.',
  changeAccount: '¿Cambiar la cuenta principal a {{ name }}?',
  addInsurance: 'Agregar seguro',
  noInsurance: 'Actualmente no tenemos ningún seguro en el archivo.',
  updateInsurance: 'Por favor, actualiza la información de tu seguro.',
  connectedSet: '¡Todo listo! Estás conectado a la cuenta del paciente de {{ name }}.',
  noConnected: 'Actualmente no hay cuentas con acceso a {{ name }}.',
  removeProvider: '¿Estás seguro de que deseas eliminar a {{ name }} como tu proveedor?',
  actions: {
    edit: 'Editar',
    confirm: 'Confirmar',
    verify: 'Verificar',
    cancel: 'Cancelar',
    updateEmail: 'Actualizar correo electrónico',
    updatePhone: 'Actualizar número de teléfono',
    backToContact: 'Volver a la información de contacto',
    download: 'Descargar',
    change: 'Cambiar',
    goBack: 'Volver',
    review: 'Revisar',
    update: 'Actualizar',
    continue: 'Continuar',
    disconnect: 'Desconectar',
    cancelRequest: 'Cancelar solicitud',
    yesSendRequest: 'Sí, enviar solicitud de acceso.',
    noDoNotRequest: 'No, no solicitar acceso.',
    acceptRequest: 'Aceptar solicitud',
    denyRequest: 'Denegar solicitud',
    upload: 'Subir',
    disconnectAccess: 'Desconectar acceso',
    takePhoto: 'Tomar una foto de mi tarjeta de seguro.',
    manuallyAdd: 'Ingresar manualmente mi información de seguro.',
    connectNewAccount: 'Conectar una nueva cuenta',
    remove: 'Eliminar',
  },
  preferredInusrance: 'Seguro preferido',
  selectInsurance: 'Seleccione su seguro.',
  reviewInsurance: 'Por favor, revisa y confirma tu seguro.',
  noReportedIssue: 'Actualmente no tenemos ningún problema reportado recientemente.',
  haveOtherCoverage: 'Sí, tengo otra cobertura.',
  dontHaveOtherCoverage: 'No, no tengo otra cobertura.',
  selectRelationship: 'Seleccionar relación',

  peopleWithAccess: 'Personas con acceso a la cuenta de {{ name }}.',
  youCanManageAccess: 'Puedes gestionar quién tiene acceso a tu cuenta y controlar qué información pueden ver.',
  sharePersonDetails: 'Por favor, comparte con nosotros los detalles de la persona que deseas agregar.',
  relationshipTo: 'Relación contigo:',
  relationshipToPatient: 'Relación con el paciente:',
  pleasereviewAndConfirmToSend: '¿Podrías revisar y confirmar la información de {{ name }}, por favor?',
  invitationSent: '¡Tu solicitud para conectar y acceder a la cuenta de {{ name }} ha sido enviada!',
  weWillNotifyYou: 'Te notificaremos una vez que haya sido aceptada.',
  permissions: 'Permisos',
  transferAccess: 'Transferir acceso',
  toGrantPrimaryAccess:
    'Para otorgar acceso primario a la cuenta de paciente de {{ name }}, por favor proporciona un número de teléfono o correo electrónico al que {{ name }} tenga acceso seguro y privado.',
  enterNewEmailPhone: 'Ingresa nuevo número de teléfono o correo electrónico',
  transferRequestSent: 'Tu solicitud de transferencia ha sido enviada a {{ contact }}.',
  feelFreeToCancelrequest: 'Siéntete libre de cancelar esta solicitud hasta que se confirme la transferencia.',
  confirmAuthority: 'Estoy autorizado para buscar atención médica para este paciente.',
  weNoticedThatPatient: 'Notamos que {{ name }} ya tiene una cuenta de paciente con nosotros.',
  wouldYouLikeRequestAccess: '¿Te gustaría solicitar acceso autorizado a su cuenta de paciente?',
  requestingAccessTo: '{{ name }} está solicitando acceso autorizado a la cuenta de paciente de {{ patientName }}.',
  reviewRequesterDetails: 'Por favor, revisa los detalles del solicitante y decide si aceptas o deniegas la solicitud.',
  requester: 'Solicitante',
  uploadYourPhoto: '¿Te gustaría subir una foto de perfil?',
  permissionsCard:
    'La cuenta de {{ name }} está configurada para usar el mismo número de teléfono que {{ connectedName }} en {{ number }}, proporcionando acceso completo.',
  weAreAllSet: '¡Todo listo! {{ name }} ahora está conectado a tu cuenta.',
  successfullyDenied:
    'Hemos denegado con éxito la solicitud de {{ requesterName }} para acceder al perfil de paciente de {{ approverName }}.',
  pleaseFeelFreeToClose: 'Por favor, siéntete libre de cerrar este navegador para salir.',
  confirmDisconnect: 'Por favor, confirma que deseas desconectar a {{ name }} de tu cuenta.',
  newAccessToAccount: 'Hola {{ approver }}, el siguiente {{ contactType }} ha sido agregado a tu cuenta: {{ contact }}',
  verifyDob: 'Para ayudar a mantener segura tu información, ¿podrías verificar tu fecha de nacimiento, por favor?',
  viewMedicalRecords: 'Ver registros médicos',
  viewAppointmentHistory: 'Ver historial de citas',
  scheduleCare: 'Programar atención',
  viewAppointmentSchedule: 'Ver horario de citas',
  dobCantVerify: 'La fecha de nacimiento que ingresaste no puede ser verificada.',
  dobCantVerifySubtitle:
    'Nuestro equipo en Next Level está aquí para apoyarte. Si crees que esto es un error, estaremos encantados de ayudarte a acceder a la aplicación Next Level.',
  relations: {
    grandparent: 'Abuelo/a',
    childDependent: 'Hijo/a dependiente',
    spouse: 'Esposo/a',
    friend: 'Amigo/a',
    primaryAccount: 'Cuenta principal',
    parent: 'Padre/madre/tutor/a',
    other: 'Otro/a',
    child: 'Hijo/a',
    self: 'Yo mismo/a',
    dependent: 'Dependiente',
  },
  pronouns: {
    she: 'Ella',
    he: 'Él',
    they: 'Elle',
    no_disclose: 'Prefiero no divulgarlo',
  },
  transferCanceled:
    'La transferencia del acceso a la cuenta principal de {{ requester }} a {{ approver }} ha sido cancelada.',
  transferAccepted: 'El acceso a la cuenta de {{ approver }} ha sido transferido con éxito a {{ contact }}.',
  accessRemoved: 'El acceso de {{ requester }} ha sido eliminado.',
  PolicyholderLastName: 'Apellido del titular de la póliza',
  PolicyholderFirstName: 'Nombre del titular de la póliza',
  MemberId: 'ID de miembro',
  CarrierName: 'Nombre del proveedor',
  PlanNumber: 'Número de plan',
  GroupNumber: 'Número de grupo',
  GroupName: 'Nombre del grupo',
  pending: 'Pendiente',
};
