import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IonHeader,
  IonProgressBar,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular/standalone';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '@services/auth.service';
import { RegistrationService } from '@services/registration.service';
import { AlertService } from '@services/alert.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { PatientService } from '@swagger-services/patient';

@Component({
  selector: 'app-get-care-base',
  standalone: true,
  templateUrl: './account-base.component.html',
  imports: [IonRouterOutlet, IonProgressBar, IonHeader, IonTitle, IonToolbar],
  providers: [RegistrationService],
})
export class AccountBaseComponent implements ViewWillEnter, ViewWillLeave {
  isLoaded: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly httpClient: HttpClient,
    private readonly patientService: PatientService,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    const profileId = this.activatedRoute.snapshot.params['id'];
    try {
      const currentUser = await this.switchAccount(profileId);
      if (currentUser) {
        const res = await this.patientService.patientControllerGetPatientInfo(environment.orgSlug, profileId);
        this.accountService.updateAccount({
          ...currentUser,
          ...res,
          name: `${res.firstName} ${res.lastName}`,
        });
        this.authService.setLanguage(res.language!);
      }
    } catch (res: any) {
      await this.alertService.showError(res.error.message);
    } finally {
      this.isLoaded = true;
    }
  }

  ionViewWillLeave(): void {
    this.accountService.clear();
  }

  private async switchAccount(patientId: string): Promise<any> {
    const token = await this.authService.getToken();
    return await firstValueFrom(
      this.httpClient.post(
        `${environment.ollaApiUrl}/api/supertokens/account/switch-account`,
        { patientId },
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-TENANT-ID': environment.orgSlug,
          },
        },
      ),
    );
  }
}
