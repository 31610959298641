import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { PreloadAllModules, provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocalTranslationLoader } from './app/translation.loader';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideNgxMask } from 'ngx-mask';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { provideServiceWorker } from '@angular/service-worker';
import { provideLottieOptions } from 'ngx-lottie';
import { provideMatomo, withRouter, withRouterInterceptors } from 'ngx-matomo-client';
import { MatomoInterceptor } from './app/interceptors/matomo.interceptor';
import { apiInterceptor } from './app/interceptors/api.interceptor';
// import { Storage } from '@ionic/storage-angular';
import { BASE_PATH as CARD_PATH } from '@swagger-services/card';
import { BASE_PATH as WAITING_ROOM_PATH } from '@swagger-services/waiting-room';
import { BASE_PATH as FRONT_DOOR_PATH } from '@swagger-services/front-door';
import { BASE_PATH as SUPERTOKENS_PATH } from '@swagger-services/supertokens';
import { AppAuthStrategy } from '@services/auth-strategy.service';
import { AuthStrategy } from '@libs/services/auth-strategy';

// Call the element loader before the bootstrapModule/bootstrapApplication call
defineCustomElements(window);
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      rippleEffect: false,
      mode: 'ios',
    }),
    provideHttpClient(withInterceptors([apiInterceptor])),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LocalTranslationLoader,
          deps: [HttpClient],
        },
      }),
    ),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideAnimations(),
    provideNgxMask(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideMatomo(
      {
        siteId: environment.matomoSiteId,
        trackerUrl: environment.matomoUrl,
        disabled: !environment.matomo,
      },
      withRouter(),
      withRouterInterceptors([MatomoInterceptor]),
    ),
    ...[CARD_PATH, WAITING_ROOM_PATH, FRONT_DOOR_PATH, SUPERTOKENS_PATH].map((path) => ({
      provide: path,
      useValue: environment.ollaApiUrl,
    })),
    { provide: AuthStrategy, useClass: AppAuthStrategy },
    // { provide: Storage },
  ],
});
