export const videoCall = {
  defaultInstructions: 'If you blocked access by accident, you can change your choice in the browser settings.',
  getInstructions: 'Get instructions',
  followInstructions: 'Follow the instructions',
  refreshInstructions: 'then refresh this page.',
  joinVisit: 'Join your visit with',
  startVisitWith: 'Start visit with',
  you: 'You',
  interpreterListening: 'Interpreter is listening',
  interpreterSpeaking: 'Interpreter is speaking',
  interpreterInitiating: 'Interpreter is initiating',
  isSpeaking: '{{ name }} is speaking',
  isListening: '{{ name }} is listening',
  youAreListening: 'You are listening',
  preferredLanguage: 'Preferred language:',
  providerLeft: 'Your consultation with {{ name }} has ended.',
  patientCanceled: 'If your consultation wasn’t complete, your provider will rejoin you shortly.',
  rejoinWaitingRoom: 'You can also rejoin the waiting room.',
  appointmentMissed: 'We’re sorry we missed you, {{ name }}!',
  sendYouLink: 'We’ll send you a new link via text and email.',
  appointmentExpired: 'Apologies! Your visit link has expired.',
  scheduleNewAppointment: 'To schedule a new appointment, simply tap the button below.',
  appointmentReady: 'Your consultation is in progress...',
  goBack: 'Let’s get you back to your visit with {{ doctor }}. Tap below to rejoin!',
  offHours: '{{ name }}, your medical team will be available today at {{ time }}.',
  weSendYouEmail: 'We’ll send you a text and email at {{ time }}. Feel free to close this page.',
  noAccessTitle: 'Your session timed out!',
  noAccessText: 'Please feel free to close close this page. To log back into the app, simply tap the button below.',
  actions: {
    visitJoin: 'Join Visit Now',
    callSupport: 'Call Support',
    connectVideoAudio: 'Connect Your Video and Audio',
    enableCamera: 'Enable Camera',
    enableAudio: 'Enable Audio',
    instructionLink: 'Here',
    allowCamera: 'Allow Camera',
    allowMicrophone: 'Allow Microphone',
    clickToAllow: 'Click to Allow',
    donePrecheck: 'Done Video Precheck',
    retryCamMic: 'Retry Camera and Mic',
    viewInstructions: 'View Instructions Here',
    getinstructions: 'Get Instructions',
    retryCam: 'Retry Camera',
    retryMic: 'Retry Microphone',
    connecting: 'Connecting',
    translate: 'Translate',
    transcript: 'Transcript',
    audio: 'Audio',
    turnOnTranslation: 'Turn On Translation',
    startVisit: 'Start Visit',
    rejoinToRoom: 'Rejoin the Waiting Room',
    rejoinNow: 'Rejoin Now',
    returnToApp: 'Return to home',
    scheduleNewAppointment: 'Schedule a New Appointment',
    logBack: 'Log Back into the App',
    returnHome: 'Return home',
  },
  title: {
    patientTrouble: 'Patient is having trouble connecting video and audio',
    patientAware: 'Patient is aware of the issue and provided support.',
    patientLink:
      'If you choose to mark this visit as unable to complete, the patient will be provided a link to rejoin the waiting room.',
    emsTrouble: 'EMS is having trouble connecting video and audio',
    emsAware: 'EMS is aware of the issue and provided support.',
    needHelp: 'Need help? Get support by calling',
    readyToJoin: 'Your visit is ready to join',
    problemCall: 'Having problems? Call ',
    allowToJoin: 'Please allow access to your camera and microphone to join this visit.',
    blockedAccess: 'You have blocked access',
    accessToJoin: 'Access to your camera and microphone is required to join this visit.',
    getHelp: 'Get help to enable access',
    videoPrecheck: 'Set up video and audio',
    cameraStarting: 'Camera is starting',
    AccessToCamMic: 'Access to your camera and microphone is blocked',
    AccesesToMic: 'Access to your microphone is blocked',
    AccesesToCam: 'Access to your camera is blocked',
    NoMicFound: 'No microphone found',
    NoCamFound: 'No camera found',
    allowAccess: 'Please allow access to use your camera and mic for this visit',
    NoCamMicFound: 'No camera and microphone found',
    translationPreferences: 'Translation preferences:',
    translating: 'Translating',
    realTimeTranslation: 'Real-time translation',
    setPreferedLanguage: 'Set your preferred language',
    selectAMicrophone: 'Select a microphone',
    selectASpeaker: 'Select a speaker',
    selectACamera: 'Select a camera',
    setupPermissions: 'Please set up your audio and video.',
    visitCancelled: 'Your visit has been cancelled.',
  },
  subtitle: {
    AccessToCamMic: 'To join a video visit, please allow access to use your camera and microphone.',
    AccesesToMic: 'To join a video visit, please allow access to use your microphone.',
    AccesesToCam: 'To join a video visit, please allow access to use your camera.',
    closePage: 'Please feel free to close this page. To return to the app, simply tap the button below.',
  },
  forms: {
    videoSource: 'Select video source',
  },
  error: {
    cameraInitializationFailed: 'Camera initialization failed',
  },
};
