@if (isLoaded) {
  <ion-router-outlet></ion-router-outlet>
} @else {
  <ion-header>
    <ion-toolbar
      align-title="center"
      class="text-center px-5 pb-4"
    >
      <ion-title class="px-0">
        <img
          src="assets/mp-logo.svg"
          alt=""
          height="23"
        />
      </ion-title>
    </ion-toolbar>
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </ion-header>
}
