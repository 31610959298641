import { CookieHandlerInterface } from 'supertokens-web-js/lib/build/cookieHandler/types';
import { CapacitorCookies } from '@capacitor/core';

export default function getCookieHandler(original: CookieHandlerInterface): CookieHandlerInterface {
  return {
    ...original,
    getCookie: async function (): Promise<string> {
      const flow = localStorage.getItem('FLOW');
      const cookies = document.cookie.replaceAll(`${flow}-`, '');
      return decodeURIComponent(cookies);
    },
    setCookie: async function (cookieString: string): Promise<void> {
      const flow = localStorage.getItem('FLOW');
      // document.cookie = flow ? `${flow}-${cookieString}` : cookieString;

      const parts = cookieString.split(';').map((part) => part.trim());
      const [nameValue, ...attributes] = parts;

      const [key, value] = nameValue.split('=');
      const httpCookie: any = { key: flow ? `${flow}-${key}` : key, value };

      attributes.forEach((attribute) => {
        const [key, val] = attribute.split('=');
        const normalizedKey = key.toLowerCase();

        switch (normalizedKey) {
          case 'expires':
            httpCookie.expires = val;
            break;
          case 'path':
            httpCookie.path = val;
            break;
          case 'samesite':
            const sameSiteValue = val.toLowerCase() as 'lax' | 'strict' | 'none';
            httpCookie.sameSite = sameSiteValue;
            break;
        }
      });

      await CapacitorCookies.setCookie(httpCookie);
    },
  };
}
