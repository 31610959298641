import { Routes } from '@angular/router';
import { PreVisitPage } from './pre-visit/pre-visit.page';
import { InviteRoutes } from '../account/invite/invite.routes';

export const getCareRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadComponent: () => import('./select-account/select-account.page').then((m) => m.SelectAccountPage),
      },
      {
        path: 'olla-video',
        loadComponent: () => import('./../olla-video/video-base/video-base.page').then((m) => m.VideoCallBaseComponent),
      },
      {
        path: 'intake',
        loadChildren: () => import('./intake/intake.routes').then((m) => m.INTAKE_ROUTES),
      },
      {
        path: 'invite',
        loadComponent: () => import('../account/invite/invite.page').then((m) => m.InvitePage),
        children: InviteRoutes,
      },
      {
        path: 'pre-visit',
        component: PreVisitPage,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'consent',
          },
          {
            path: 'consent',
            loadComponent: () => import('./pre-visit/consent/consent.component').then((m) => m.ConsentComponent),
            data: {
              progress: 25,
              label: 'getCare.consent',
            },
          },
          {
            path: 'employer',
            loadComponent: () =>
              import('./pre-visit/employer/choose-employer.component').then((m) => m.ChooseEmployerComponent),
            data: {
              progress: 50,
              label: 'getCare.benefits',
            },
          },
          {
            path: 'confirmation',
            loadComponent: () =>
              import('./pre-visit/confirmation/pre-visit-confirmation.component').then(
                (m) => m.PreVisitConfirmationComponent,
              ),
            data: {
              progress: 50,
              label: 'getCare.benefits',
            },
          },
          {
            path: 'primary-care-provider',
            loadComponent: () =>
              import('./pre-visit/primary-provider/primary-provider.component').then((m) => m.PrimaryProviderComponent),
            data: {
              progress: 75,
              label: 'getCare.primaryCareProvider',
            },
          },
        ],
      },
      {
        path: 'payment-preferences',
        loadComponent: () =>
          import('./payment-preferences/payment-preferences.page').then((m) => m.PaymentPreferencesPage),
        data: { shouldShowTabBar: false },
      },
      {
        path: 'payment-later',
        loadComponent: () => import('./payment-later/payment-later.page').then((m) => m.PaymentLaterPage),
        data: { shouldShowTabBar: false },
      },
    ],
  },
];
