import { MatomoRouterInterceptor, MatomoTracker } from 'ngx-matomo-client';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MatomoInterceptor implements MatomoRouterInterceptor {
  private readonly tracker = inject(MatomoTracker);
  private readonly authService = inject(AuthService);

  async beforePageTrack(): Promise<void> {
    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated) return;

    const tokenPayload = await this.authService.getTokenPayload();
    this.tracker.setUserId(
      tokenPayload.subUser ? `${tokenPayload.patientId}/${tokenPayload.subUser.patientId}` : tokenPayload.patientId,
    );
  }
}
